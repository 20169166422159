import axios from 'axios'
import { ENV } from "../../../constants";
import { UserModel } from "../models/UserModel";

// const API_URL = process.env.REACT_APP_API_URL || 'api'
const API_URL = "https://467xh4opj1.execute-api.ap-southeast-2.amazonaws.com";

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL = `${API_URL}/${ENV}/customersettings/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  organisationName: string,
  phoneNumber: string,
  mobileNumber: string,
  country: string,
  streetAddress: string,
  city: string,
  state: string,
  postalCode: string,
  acceptTerms: Boolean,
  token: string) {

  console.log("making axios call POST", REGISTER_URL)

  console.log("Request Body", {
    email,
    firstname,
    lastname,
    organisationName,
    phoneNumber,
    mobileNumber,
    country,
    streetAddress,
    city,
    state,
    postalCode,
    acceptTerms,
  });

  return fetch(REGISTER_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify({
      email,
      firstname,
      lastname,
      organisationName,
      phoneNumber,
      mobileNumber,
      country,
      streetAddress,
      city,
      state,
      postalCode,
      acceptTerms,
    })
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
