import { FC, useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Link } from "react-router-dom";
import {
  getUsers,
  users,
  singleDeleteHandler,
  deleteUserModal
} from "./userManagement-hooks";
import CustomerDataContext from "../../../store/CustomerData-context";
import { AlertWidget, ModalWidget, TableLoader } from "../../UI";
import "./UserManagement.css";
// for grabbing loginRole
// import { shallowEqual, useSelector } from "react-redux";
// import { RootState } from "../../../setup";
import { uuid } from "../../../hooks/utils";
import { Breadcrumb } from "../../../_metronic/layout/components/header/Breadcrumb";
import Cookies from "universal-cookie";
import { MultiSelect } from "react-multi-select-component";
const cookies = new Cookies();
const multiSelectDropdown = document.getElementsByClassName(
  "dropdown-heading-value"
) as HTMLCollectionOf<HTMLElement>;

const UserManagement: FC = () => {
  const intl = useIntl();
  // const loginRole = useSelector<RootState>(
  //   ({ auth }) => auth.loginRole,
  //   shallowEqual
  // ); // grabbing login role from redux
  cookies.get("site_admin") !== "1" && window.location.replace("/"); // this page is only accessible to site_admins

  const [usersList, setUsersList] = useState<users[]>([]);
  const [searchResults, setSearchResults] = useState<users[]>([]);
  const [selectedSites, setSelectedSites] = useState<any>([]);

  const [loader, setLoader] = useState(true);
  // States for deleting single user
  const [delUserID, setdelUserID] = useState("");
  const [delName, setdelName] = useState("");
  const [delModal, setDelModal] = useState(false);
  const [dispAlertMessage, setDispAlertMessage] = useState<string | boolean>(
    ""
  );

  // search feature states
  const [search, setSearch] = useState("");
  const customerCtx = useContext(CustomerDataContext);
  const sites = customerCtx.sites;

  useEffect(() => {
    getUsers({ user_type: "user" }).then((response) => {
      setUsersList(response.returnData);
      setLoader(false);
    });
  }, []);

  // Handling Table Search Field Input
  useEffect(() => {
    filterUserList();
  }, [search]);

  const filterUserList = () => {
    if (search.length === 0 && selectedSites.length === 0){
      setSearchResults([])
      return;
    }

    let results:any = [];
    if (search.length > 0){
      for (const user in usersList)
        usersList[user].search_filters.toLowerCase().search(search.toLowerCase()) !== -1 &&
        results.push(usersList[user]);
    }

    if (search.length === 0)
      results = usersList;

    if (selectedSites.length > 0) {
      let tmp_results:any = [];
      // iterate through each selected sites and check if the site id exists in the user's site_access key field
      // if found capture the object key id for now
      // debugger;
      selectedSites.forEach((site: any) => {
        for (const objectKey in results) {
          if(!results[objectKey].site_access)
            continue;

          if (results[objectKey].site_access.includes(site.value)){
            tmp_results.push(Number(objectKey));
          }
        }
      });

      // get unique object key ids
      const uniqResults = results.filter(function (item: number, pos: number) {
        return (tmp_results.includes(pos));
      });

      results = uniqResults;
    }

    setSearchResults(results);
  };

  const siteBubbles = (site_access: string[]) => {
    const diff = site_access.length - 3;
    let dispCounter = 3;
    return site_access.map((access: any) => {
      let bubble = dispCounter >= 0 && (
        <p key={uuid()} className="accessBubbles">
          {[sites.find((element) => element["site_id"] === access)][0]
            ? [sites!.find((element) => element["site_id"] === access)][0]![
                "site_name"
              ]
            : access}
        </p>
      );
      dispCounter--;
      return dispCounter === -1 ? (
        <>
          <p
            className="accessBubbles"
            style={{ background: "transparent", fontWeight: "bold" }}
          >
            {" "}
            + {diff} more
          </p>
        </>
      ) : (
        bubble
      );
    });
  };

  const listTable = (user: users) => {
    return (
      <tr key={uuid()} style={{ verticalAlign: "middle" }}>
        {/* <td><input className="form-check-input" type="checkbox" onChange={() => updateCheckedStatus(user.user_login_id, usersList, setBulkOptionsDisabled, setUsersList)}/></td> */}
        <td>{user.first_name}</td>
        <td>{user.last_name}</td>
        <td>
          {user.site_admin ? (
            <span className="label-green">
              <i className="bi bi-check fs-1"></i>
            </span>
          ) : (
            <span className="label-red">
              <i className="bi bi-x fs-1"></i>
            </span>
          )}
        </td>
        <td>{user.email}</td>
        <td>
          {user.site_access && user.site_access.length !== sites.length ? (
            siteBubbles(user.site_access)
          ) : (
            <b>ALL</b>
          )}
        </td>
        <td>
          {user.menu_config && user.menu_config.length !== 0 ? (
              user.menu_config.map((access) => {
                  return (
                      <p key={uuid()} className="accessBubbles">
                        {access.replaceAll("_", " ")}
                      </p>
                  );
              })
          ) : (
              <b>DASHBOARD ONLY</b>
          )}
        </td>
        <td>
          <Link
            className="btn btn-sm actionButton btn-round btn-orange mt-2"
            title="Edit"
            to={`/users/${user.user_login_id}`}
          >
            <i className="bi bi-pencil-fill fs-3"></i>
          </Link>
          <button
            className="btn btn-sm actionButton btn-round btn-red mt-2"
            title="Delete"
            onClick={() =>
              deleteUserModal(
                user.user_login_id,
                `${user.first_name} ${user.last_name} (${user.username})`,
                setdelUserID,
                setdelName,
                setDelModal
              )
            }
          >
            <i className="bi bi-trash fs-3"></i>
          </button>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    // setting text for the dropdown select field
    if (selectedSites.length === 0) {
      multiSelectDropdown[0].innerText = "Filter By Sites";
    } else {
      selectedSites.length > 1
        ? (multiSelectDropdown[0].innerText = `${selectedSites[0].label} + ${
            selectedSites.length - 1
          } more`)
        : (multiSelectDropdown[0].innerText = `${selectedSites[0].label}`);
    }
    filterUserList();
  }, [selectedSites]);

  const UserManagementBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU.SETUP" }),
      path: "/setup.html",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU.USERACCOUNTS" }),
      path: "/users",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <>
      {dispAlertMessage && <AlertWidget message={dispAlertMessage} />}

      <Breadcrumb
        pageBreadcrumbs={UserManagementBreadcrumbs}
        activePageName={"user_accounts"}
      />
      {/* Setting Active Page Name in the Context */}
      <PageTitle activePageName={"user_accounts"}></PageTitle>
      {/* MODAL SINGLE USER DELETION */}
      <ModalWidget
        title="Deleting User Account"
        modalBody={
          <p style={{ fontSize: "17px" }}>
            Are you sure you want to delete {delName}?
          </p>
        }
        closeModal={() => setDelModal(false)}
        showModal={delModal}
        closeBtnText="Close"
        confirmBtnText="Confirm"
        confirmBtnAction={() =>
          singleDeleteHandler(
            usersList,
            delUserID,
            setDispAlertMessage,
            setUsersList,
            setDelModal,
            setSearch
          )
        }
      />
      {/* begin::Row */}
      <div className="row gy-5 gx-xl-8">
        <div className="col-xxl-12">
          <div className="card card-custom">
            {/* end::Header */}
            <div className="card-header">
              <h3 className="card-title">
                {intl.formatMessage({ id: "MENU.USERACCOUNTS" })}
              </h3>
              <div
                className="col-sm-12 col-md-8 col-xxl-6"
                style={{
                  float: "right",
                  display: "flex",
                  flexWrap: "inherit",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="col-md-4 col-xxl-4">
                  <MultiSelect
                    options={sites.map((site: any) => {
                      return { value: site.site_id, label: site.site_name };
                    })}
                    value={selectedSites}
                    onChange={setSelectedSites}
                    hasSelectAll={true}
                    disableSearch={false}
                    labelledBy="Filter based on Sites"
                  />
                </div>
                <input
                  type="text"
                  style={{ width: "30%", margin: "0px 15px" }}
                  className="form-control form-control-sm px-5 p-3"
                  name="searchUser"
                  placeholder="Search User Account"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Link className="btn btn-ll my-4" to="/adduser">
                  Add New User
                </Link>
              </div>
            </div>
            <div className="card-body">
              <table className="table align-center gs-0 gy-5">
                <thead>
                  <tr key={uuid()} style={{ verticalAlign: "middle" }}>
                    {/* <th style={{width:"3%"}}><input className="form-check-input" type="checkbox" checked={selectAllCheck} onChange={() => selectAllHandler(selectAllCheck, setSelectAll, setSelectAllCheck, usersList, setUsersList, setCheckedUsers)}/></th> */}
                    <th style={{ width: "10%" }}>First Name</th>
                    <th style={{ width: "10%" }}>Last Name</th>
                    <th style={{ width: "10%" }}>Site Admin</th>
                    <th style={{ width: "20%" }}>Email</th>
                    <th style={{ width: "20%" }}>Site Access</th>
                    <th style={{ width: "20%" }}>Menu Access</th>
                    <th style={{ width: "10%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader && <TableLoader rows={3} columns={7} />}
                  {usersList && usersList.length === 0 && loader === false && (
                    <tr key={uuid()}>
                      <td colSpan={7}>
                        <b>No Users Found!</b>
                      </td>
                    </tr>
                  )}
                  {search.length === 0 &&
                   selectedSites.length === 0 &&
                    usersList.map((user) => listTable(user))}
                  {(search.length > 0 || selectedSites.length > 0) && searchResults.length === 0 && (
                    <tr key={uuid()}>
                      <td colSpan={7}>
                        <b>0 users found</b>
                      </td>
                    </tr>
                  )}
                  {searchResults.length > 0 &&
                    searchResults.map((user) => listTable(user))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { UserManagement };
