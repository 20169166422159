import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG } from "../../../helpers";
import { useLayout } from "../../core";
import "./asidemenuitem.css";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  const menuItemContent = () => {
    return <>
    {hasBullet && (
      <span className="menu-bullet">
        <span className="bullet bullet-dot"></span>
      </span>
    )}
    {icon && aside.menuIcon === "svg" && (
      <span className="menu-icon">
        <KTSVG path={icon} className="svg-icon-2" />
      </span>
    )}
    {fontIcon && aside.menuIcon === "font" && (
      <i className={`bi ${fontIcon} fs-1`}></i>
    )}
    <span className="menu-title">{title}</span>
    </>
  }
  return (
    <div className="menu-item">
      {to.includes('.html') && (
        <a className={clsx("menu-link without-sub", { active: isActive })} href={to}>
          {menuItemContent()}
        </a>
      )}
      {!to.includes('.html') && (
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={to}
        >
          {menuItemContent()}
        </Link>
      )}
      {children}
    </div>
  );
};

export { AsideMenuItem };
