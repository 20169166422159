/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useContext } from "react";

// imports for page components and data
import moment from 'moment';
import { getSignInLogs, showLogs, downloadReport, dashWidgets, getCustomerInfo, getPeopleRecords, getSelectedWidgets, setDashConfig } from "./dashboard-hooks";

// imports for widgets
import { StaffOnSite, VisitorsOnSite, StudentPartialAttendance, UpcomingScheduledVisits, OverdueVisitorsOnSite, YearLevel } from "./widgets";
// UI Imports
import { DateWidget, AlertWidget, ModalWidget, NotificationWidget } from "../../UI";
import { MultiSelect } from "react-multi-select-component";
import CustomerDataContext from "../../../store/CustomerData-context";
import UserInfoContext from "../../../store/UserInfo-context";
import ConstantsContext from "../../../store/Constants-context";

// for grabbing loginRole
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import "./Dashboard.css";
import { Breadcrumb } from "../../../_metronic/layout/components/header/Breadcrumb";
import { PageTitle } from "../../../_metronic/layout/core";

const DashboardPage: FC = () => {
  const customerCtx = useContext(CustomerDataContext);
  const userInfoCtx = useContext(UserInfoContext);
  const constantsCtx = useContext(ConstantsContext);
  const loginRole = useSelector<RootState>(({ auth }) => auth.loginRole, shallowEqual); // grabbing login role from redux
  // Accessing PageDataContext and setting active page name and page title
  // const pageData = usePageData();
  // pageData.setActivePageName("dashboard");
  // pageData.setPageTitle("Dashboard");

  const [dispNotification, setDispNotification] = useState(false);
  const [downloadLink, setDownloadLink] = useState("#");
  const [dispAlertMessage, setDispAlertMessage] = useState<string | boolean>("");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState("ALL"); // used for education customer type
  const [dashboardWidgets, setDashboardWidgets] = useState(getSelectedWidgets(customerCtx, constantsCtx));
  const [selectedWidgets, setSelectedWidgets] = useState(getSelectedWidgets(customerCtx, constantsCtx));
  const [selectedWidgetValues, setSelectedWidgetValues] = useState<string[]>([]);
  const [signInLogs, setSignInLogs] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setDashboardWidgets(dashWidgets(customerCtx, constantsCtx));
    setSelectedWidgets(getSelectedWidgets(customerCtx, constantsCtx));
  },[customerCtx, constantsCtx]);

  useEffect(() => {
    setDashConfig(setSelectedWidgetValues, selectedWidgets, loginRole);
    // we want to customize the text that appears on the multi-select third party component
    const multiSelectDropdown = document.getElementsByClassName("dropdown-heading-value") as HTMLCollectionOf<HTMLElement>;
    multiSelectDropdown[0].innerText = "Filter Dashboard"
  }, [selectedWidgets, loginRole]);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        getCustomerInfo(customerCtx, constantsCtx, loginRole),
        getPeopleRecords(userInfoCtx, setUsers),
        getSignInLogs( startDate, userInfoCtx, customerCtx, constantsCtx, setSignInLogs, setSelectedWidgets)
      ]);
    };
    loadData();
  }, [startDate, selectedYear, customerCtx, constantsCtx, loginRole, userInfoCtx]);
  return (
  <>
    {dispAlertMessage && <AlertWidget message={dispAlertMessage}/>}
    <Breadcrumb pageBreadcrumbs={{}} activePageName={'dashboard'}/>
    {/* Setting Active Page Name in the Context */}
    <PageTitle activePageName={'dashboard'}></PageTitle>
    {/* begin::ItemNotification */}
    {dispNotification && (
      <NotificationWidget
        notification="Your export file has been delivered."
        notificationType="success"
        downloadLink={downloadLink}
        dispCloseButton={true}
        ctaButtonMessage="Click Here to Download"
        closeBtnAction={() =>  setDispNotification(false)} />
    )}
    {/* end::ItemNotification */}

    {/* begin::Row */}
    <div className="row gy-5 mb-2 gx-xl-8">
      <div className="col-xxl-3">
        <DateWidget className="card-xxl-50 mb-xl-8 card-dashboard" startDate={startDate} onChange={(e: any) => { setStartDate(e)}} />
      </div>
      {(customerCtx.customer_type === 'education') && (
      <div className="col-xxl-2">
        <YearLevel className="card-xxl-50 mb-xl-8 card-dashboard" users={users} selectedYear={selectedYear} yearChange={(e: string) => {setSelectedYear(e)}} />
      </div>
      )}
      <div className="col-xxl-5">
        <div className={`card card-dashboard`}>
          {/* begin::Body */}
          <div className='card-body pt-6 py-6 px-5'>
            <div className="row">
              {/* <div className="col-md-3 pt-3">
                <span className='fw-bolder mb-2 text-dark'>Filter Dashboard: </span>
              </div> */}
              <div className="col-md-12 filterDashboard">
              <MultiSelect
                options={dashboardWidgets}
                value={selectedWidgets}
                onChange={setSelectedWidgets} 
                hasSelectAll={false}
                disableSearch={false}
                labelledBy="Select" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-2 py-6">
        <a href='#' className='btn btn-ll btn-block' onClick={() => setShowModal(true)}>EVAC REPORT</a>
        <ModalWidget
          title="Confirm"
          modalBody={<p style={{fontSize:"17px"}}>Generate Emergency Evacuation Report?</p>}
          closeModal={() => setShowModal(false)}
          showModal={showModal}
          closeBtnText="Close"
          confirmBtnText="Confirm"
          confirmBtnAction={() => downloadReport(setDownloadLink, setDispNotification, setDispAlertMessage, setShowModal)}
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      {/* selectedWidgetValues.includes('present_students') &&
      <PresentMember date={moment(startDate).format('YYYY-MM-DD')} users={users} yearLevel={selectedYear} customerType={customerCtx.customer_type} />} {/* REQUIRE TESTING with data */}

      {selectedWidgetValues.includes('visitors_on_campus') &&
      <VisitorsOnSite signInLogs={signInLogs}  showLogs={() => {showLogs('visitor', userInfoCtx, constantsCtx.cTimeZoneOffset)}}/>}  

      {selectedWidgetValues.includes('staff_on_campus') &&
      <StaffOnSite signInLogs={signInLogs} showLogs={() => {showLogs('teacher', userInfoCtx, constantsCtx.cTimeZoneOffset)}}/>}

      {/* selectedWidgetValues.includes('not_seen_one_hour') &&
      <NotSeenOneHour date={moment(startDate).format('YYYY-MM-DD')} users={users} yearLevel={selectedYear} customerType={customerCtx.customer_type} /> } {/* REQUIRE TESTING with data */}

      {selectedWidgetValues.includes('student_partial_attendance') &&
      <StudentPartialAttendance date={moment(startDate).format('YYYY-MM-DD')} users={users} yearLevel={selectedYear} customerType={customerCtx.customer_type}/>}  {/* REQUIRE TESTING with data */}

      {/* selectedWidgetValues.includes('needs_verification') &&
      <UnverifiedPeople date={startDate} /> */}

      {selectedWidgetValues.includes('upcoming_scheduled_visits') &&
      <UpcomingScheduledVisits startDate={startDate} signInLogs={signInLogs}/>}

      {selectedWidgetValues.includes('overdue_visitors_on_site') &&
      <OverdueVisitorsOnSite signInLogs={signInLogs} />}
    </div>
    {/* end::Row */}
  </>
  )
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };