import { FC, useContext, useState, useEffect } from 'react';
import CustomerDataContext from "../../../../store/CustomerData-context";
import { StudentPartialAttendanceRecords, StudentPartialAttendance_Records} from '../dashboard-hooks';

type Props = {
    date: string,
    users: any[],
    yearLevel: string,
    customerType: string
}

const StudentPartialAttendance: FC<Props> = ({date, users = [], yearLevel}) => {
    const customerCtx = useContext(CustomerDataContext)
    const [partialAttendanceCount, setPartialAttendanceCount] = useState(0);
    const [partialAttendanceRecords, setPartialAttendanceRecords] = useState<StudentPartialAttendance_Records[]>([]);
    
    useEffect(() => {
        let identifiedStudents = {
            'identified_people': [],
            'last_seen_people': []
        }
        StudentPartialAttendanceRecords(users, date, identifiedStudents, customerCtx, setPartialAttendanceCount, setPartialAttendanceRecords);
    }, [customerCtx.customer_data, date, yearLevel])

    return (
        <div className="col-xl-8 unverifiedPeople">
            <div className="card card-dashboard card-stretch">
            {/* begin::Body */}
            <div className='card-body py-9'>
                <div className="row">
                <div className="col-md-12">
                    <h4 className="py-3">Student Partial Attendence: </h4>
                </div>
                <div className="col-md-4 align-items-center d-flex flex-grow-1 justify-content-center m-b-0 m-t-0">
                    <h1>{partialAttendanceCount}</h1>
                </div>
                <div className="col-md-8 text-center table-responsive">
                    <table className="no-footer table align-middle gs-0 gy-5" id="tbl_partial_no_records">
                    <tbody>
                        { partialAttendanceCount === 0 && <tr><td className="noRecords">No records available...</td></tr>}
                        { partialAttendanceCount > 0 && 
                            partialAttendanceRecords.map((record: StudentPartialAttendance_Records) => {
                                    <tr key={0}>
                                        <td>{record.full_name}</td>
                                        <td style={{width:"68%"}}>
                                            <div className="attendance-indicator m-r-5 justify-content-end width-full m-b-10 auto-fit d-flex">
                                                <label className="center-align flex-grow-1 period teal white-text">{record.shortname}</label>
                                                </div>
                                        </td>
                                        <td style={{width:"2%"}}>
                                            <a style={{cursor:"pointer"}} href={`students_results.html?id=${record.people_people_id}&date=${date}`}>
                                                <i className="bi bi-chevron-right fs-1"></i>
                                            </a>
                                        </td>
                                    </tr>
                            })
                        }
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export {StudentPartialAttendance};
