import moment from 'moment-timezone';
/* exported ipAddressRegx,loadDataTable, generateUUID, isJson, Toaster, getLocationObject, exportCSVFile, convertToCSV, initLocalStorage, downloadCSV, initMultiDatePicker, initSingleDateTimePicker,initSingleDatePicker, checkIsImage,validateJson, showLoader, hideLoader,replace_blank_with_null, initSingleTimePicker*/

function convertToCSV(objArray, customHeaders= {}) {
    // let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    // let str = `Report Generated: ${moment().format('DD/MM/YYYY HH:mm')}\r\n`;

    let items = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    let headers = Object.keys(customHeaders);
    let csv = items.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift(Object.values(customHeaders).join(','))
    csv = csv.join('\r\n')
    return csv
}

export function exportCSVFile(headers, items, fileTitle,mail=false) {
    // if (headers) {
    //     items.unshift(headers);
    // }
    /*CONVERT OBJECT TO JSON*/
    let jsonObject = JSON.stringify(items);
    let csv = convertToCSV(jsonObject, headers);
    let exportedFilename = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        let url = URL.createObjectURL(blob);
        let date = moment().add(10, 'seconds')
        let id=Math.floor(Math.random() * 100000) + 1
        let data = {
            'todate': moment(date, 'X').utc().format(moment.defaultFormat),
            'documentLink': url,
            'id': id
        }
        localStorage.setItem('toasterData',JSON.stringify(data))
        // showExportCSVtoaster()
    }
}

// function loadDataTable(className, sort, order, exportBtn,checkbox=false) {
//     jQuery(className)
//         .dataTable()
//         .fnDestroy();
//     let options = {
//         pageLength: 10,
//         responsive: true,
//         dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
//         order: [
//             [sort, order]
//         ]
//     };
//     if (exportBtn) {
//         options.buttons = [{
//                 extend: 'excelHtml5',
//                 title: 'Students_' + moment().format('DD_MM_YYYY_HH_MM_SS'),
//                 text: 'Export to excel',
//                 exportOptions: {
//                     columns: [0, 1, 2, 3]
//                 }
//             },
//             {
//                 extend: 'pdfHtml5',
//                 title: 'Students_' + moment().format('DD_MM_YYYY_HH_MM_SS'),
//                 text: 'Export to PDF',
//                 exportOptions: {
//                     columns: [0, 1, 2, 3]
//                 }
//             },

//             {
//                 extend: 'csvHtml5',
//                 title: 'Students_' + moment().format('DD_MM_YYYY_HH_MM_SS'),
//                 text: 'Export to CSV',
//                 exportOptions: {
//                     columns: [0, 1, 2, 3]
//                 }
//             },
//         ];
//     }

//     let table = $(className).DataTable(options);
//     table.on('draw', function() {
//         $('.materialboxed').materialbox();
//     });
// }


/*THIS FUNCTION IS USED TO TOAST ERROR MESSAGE*/
// function Toaster(error) {
    // console.log(error);
    // let errMessage = error.responseJSON && error.responseJSON.userMessage ? error.responseJSON.userMessage : 'Something went wrong!';
    // Materialize.toast(errMessage, 3500);
// }
/* Ends */

/*Check string is in json format or not*/
// function isJson(str) {
    // try {
    //     JSON.parse(str);
    // } catch (e) {
    //     return false;
    // }
    // return true;
// }

/* This function is used to generate UUID / people_id*/
// function generateUUID(type) {
    // let d = new Date().getTime();
    // let uuid = '';
    // if (type === 'small') {
    //     uuid = 'xxxxxxxx-xxxx'.replace(
    //         /[xy]/g,
    //         function(c) {
    //             let r = (d + Math.random() * 16) % 16 | 0;
    //             d = Math.floor(d / 16);
    //             return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    //         }
    //     );
    //     return uuid;
    // } else {
    //     uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    //         /[xy]/g,
    //         function(c) {
    //             let r = (d + Math.random() * 16) % 16 | 0;
    //             d = Math.floor(d / 16);
    //             return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    //         }
    //     );
    //     return uuid;
    // }
// }

/* This function is used to check object have required key's & each key should have values(not null) */
// function validateJson(keysArr, obj) {
//     // let keysArr = ["first_name","last_name","user_name","password","email","test"];
//     // let obj =  {"first_name": "test", "last_name": "test", "user_name": "CS", "password": "123123","email": "test.user@gmail.com","other_names":""}
//     let nullCounter = 0;
//     // _.each(keysArr, function(key) {
//     //     if (!obj[key]) {
//     //         console.log("the key " + key.toString() + " is not in the object: " + obj.toString());
//     //         nullCounter += 1;
//     //     }
//     // });
//     if (nullCounter <= 0) {
//         return 'true';
//     } else {
//         return 'false';
//     }
// }

/* This function is used to check file extension is image type or not */
// function checkIsImage(filename) {
//     let extension = filename.split('.').pop(); // getting file extension
//     if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') { // check extension is jpgm,jpeg,png or not
//         return true; // if condition matched then return true
//     } else {
//         return false; // else return false
//     }
// }

/* This function is used to initialize time picker*/
// function initSingleTimePicker(component, cancelValue = '00:00') {
    // $(component).daterangepicker({
    //     singleDatePicker: true,
    //     timePicker: true,
    //     timePicker24Hour: true,
    //     timePickerIncrement: 1,
    //     timePickerSeconds: false,
    //     locale: {
    //         format: 'HH:mm'
    //     }
    // }).on('show.daterangepicker', function(ev, picker) {
    //     picker.container.find('.calendar-table').hide();
    // });

    // $(component).on('cancel.daterangepicker', function() {
    //     $(this).val(cancelValue);
    // });
// }


/* This function is used to initialize single date picker*/
// function initSingleDatePicker() {
    // $('.datepicker').val('');
    // $('.datepicker').daterangepicker({
    //     'singleDatePicker': true,
    //     'autoApply': true,
    //     'period': 'week',
    //     'startDate': moment(),
    //     'endDate': moment(),
    //     'locale': {
    //         'format': 'DD MMM YYYY',
    //         'separator': ' to '
    //     },
    // });
    // $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
    //     $(this).val(picker.startDate.format('DD MMM YYYY'));
    // });

    // $('.datepicker').on('cancel.daterangepicker', function() {
    //     $(this).val('');
    // });
// }

/* This function is used to initialize single date time picker*/
// function initSingleDateTimePicker(component) {
    // $(`${component}`).daterangepicker({
    //     singleDatePicker: true,
    //     timePicker: true,
    //     timePicker24Hour: true,
    //     timePickerIncrement: 1,
    //     startDate: moment(),
    //     endDate: moment(),
    //     locale: {
    //         format: 'DD MMM YYYY HH:mm:ss'
    //     }
    // });

    // $(`${component}`).on('apply.daterangepicker', function(ev, picker) {
    //     $(this).val(picker.startDate.format('DD MMM YYYY HH:mm:ss'));
    // });

    // $(`${component}`).on('cancel.daterangepicker', function() {
    //     $(this).val('');
    // });
// }

/* This function is used to initialize multi date picker */
// function initMultiDatePicker(component,days = 3) {
    // $(`${component}`).val('');
    // $(`${component}`).daterangepicker({
    //     startDate: moment().subtract(days, 'days'),
    //     endDate: moment(),
    //     locale: {
    //         format: 'DD MMM YYYY',
    //         separator: ' to '
    //     }
    // });
    // $(`${component}`).on('apply.daterangepicker', function(ev, picker) {
    //     $(this).val(picker.startDate.format('DD MMM YYYY') + ' to ' + picker.endDate.format('DD MMM YYYY'));
    // });

    // $(`${component}`).on('cancel.daterangepicker', function() {
    //     $(this).val('');
    // });
    // let selectedDate = $('.datepicker').val();
    // let splitDate = selectedDate.split('to');
    // fromDate = `${moment(splitDate[0].trim(), 'DD MMM YYYY').format('YYYY-MM-DD')}T00:00:00${Constants.cTimeZoneOffset}`;
    // toDate = `${moment(splitDate[1].trim(), 'DD MMM YYYY').format('YYYY-MM-DD')}T23:59:00${Constants.cTimeZoneOffset}`;
// }

/* THIS FUNCTION IS USED TO CONVERT JSON ARRAY TO CSV
function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data === null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) { result += columnDelimiter; }

            result += item[key];
            ctr += 1;
        });
        result += lineDelimiter;
    });

    return result;
}
*/
/* THIS FUNCTION IS USED TO CONVERT JSON ARRAY TO CSV AND DOWNLOAD*/
// ARGS WILLBE FILE_NAME AND EXPORTDATA
// function downloadCSV(args) {
//     let data, filename, link;
//     let csv = convertArrayOfObjectsToCSV({
//         data: args.exportData
//     });
//     if (csv === null) { return; }
//     try{
//         filename = `${args.importType}_${new Date().getTime()}_not_imported.csv`;
//         if (!csv.match(/^data:text\/csv/i)) {
//             csv = 'data:text/csv;charset=utf-8,' + csv;
//         }
//         data = encodeURI(csv);

//         link = document.createElement('a');
//         link.setAttribute('href', data);
//         link.setAttribute('download', filename);
//         link.click();

//     } catch(err) {
//         console.log(err);
//     }

// }

/*FUNCTION IS UED TO REINITIALIZE LOCAL STORAGE DATA AND GLOBAL SEARCH DATA*/
// function initLocalStorage(type, data) {
//     if (type === 'students') {

//         let clientStudents = [];
//         data.forEach(function(eachStudent) {
//             clientStudents.push({
//                 'id': eachStudent.people_id,
//                 'text': `${eachStudent.first_name} ${eachStudent.last_name} (Student)`
//             });
//         });

//         localStorage.setItem('searchStudents', JSON.stringify(clientStudents));

//     } else if (type === 'rooms') {
//         let clientRooms = [];
//         data.forEach(function(eachRoom) {
//             clientRooms.push({ 'id': eachRoom.name, 'text': `${eachRoom.name}` });
//         });
//         localStorage.setItem('searchRooms', JSON.stringify(clientRooms));
//     }
// }



/*USED TO GENERATE ARRAY OF ROOM NAME AND KIOSK DEVICE*/
// async function getLocationObject() {
    // return new Promise(async function(resolve) {
    //     let roomsArr = [];
    //     try {
    //         let customerDetails = await getCustomerData(cCode);
    //         if (customerDetails && customerDetails.sites && customerDetails.sites.length > 0) {
    //             site = customerDetails.sites.filter(site => site.site_id === $.cookie('activeSite'))
    //             if (site.length && site.rooms){
    //                 let rooms = site.rooms;
    //                 for (let room of rooms) {
    //                     if (room.kiosk_devices && room.kiosk_devices.length) {
    //                         for (let kiosk_device of room.kiosk_devices) {
    //                             roomsArr.push({ 'name': room.name, 'kiosk_device_id': kiosk_device });
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         resolve({ rooms: roomsArr });
    //     } catch (err) {
    //         resolve({ rooms: roomsArr });
    //     }
    // });
// }

/* FUNCTION IS USED TO SHOW LOADER */
// function showLoader() {
    // $('.overlay, .loading').show();
// }

/* FUNCTION IS USED TO HIDE LOADER */
// function hideLoader() {
    // $('.overlay, .loading').hide();
// }


/*FUNCTION IS USED TO REPLACE BLANK VALUES WITH NULL*/
// function replace_blank_with_null(object){
//     return new Promise(function(resolve) {
//         Object.keys(object).forEach(function(key) {
//           if(object[key] === '' || object[key] === undefined) {
//             object[key] = null;
//           }
//         });
//         resolve(object);
//     });
// }

// FUNCTION IS USED TO CHANGE CURRENT SITE AND REDIRECT TO URL
// function viewContactDetails(redirect_uri,site_id){
    // $("#current_site").val(site_id).trigger("change")
    // $.cookie("activeSite",site_id)
    // Redirector(redirect_uri)
// }