import React, {Suspense, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {UserManagement} from '../pages/UserManagement'
import {AddUser} from '../pages/UserManagement/AddUser'
import {EditUser} from '../pages/UserManagement/EditUser'
import AuthContext from "../../store/Auth-context";
import ConstantsContext from "../../store/Constants-context";

export function PrivateRoutes() {
  const authCtx = useContext(AuthContext);
  const constantsCtx = useContext(ConstantsContext);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {authCtx.isLoggedIn && <Route path='/dashboard' component={DashboardWrapper} /> }
        {authCtx.isLoggedIn && <Route path='/adduser' component={AddUser} /> }
        {authCtx.isLoggedIn && <Route path='/users/:id' component={EditUser} />}
        {authCtx.isLoggedIn && <Route path='/users' component={UserManagement} />}
        {authCtx.isLoggedIn && <Redirect exact from='/' to='/dashboard' /> }
        {!authCtx.isLoggedIn && 
          <Route path='/' component={() => { 
            window.location.href = constantsCtx.logoutUrl; 
            return null;
          }} />
        }
        <Route path="*">
          <Redirect to='/' />
        </Route>
      </Switch>
    </Suspense>
  )
}
