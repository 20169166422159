import clsx from "clsx";
import React, { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import {ENV} from "../../../../app/constants";
// LoopLearn Imports
import "./Topbar.css";
import Cookies from "universal-cookie";

const toolbarButtonMarginClass = "",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {

  const { config } = useLayout();
  const cookies = new Cookies();
  let user_full_name = "No user name found"
  if(cookies.get("user_full_name") !== undefined){
    user_full_name = `${cookies.get("user_full_name").split(" ")[0]} ${cookies.get("user_full_name").split(" ")[1].charAt(0)}.`;
  }

  return (
    <>
      {ENV !== "prod" && ( 
        <div className="d-flex align-items-center appEnv">
            <h1 className="pt-2">{ENV}</h1>
        </div>
      )}
      <div className="d-flex align-items-center helpLink" style={{marginLeft:"20px"}}>
        <a href="https://looplearn.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">
          <h4 className="text-white pt-2">Help</h4>
        </a>
      </div>

      <div className="d-flex align-items-stretch flex-shrink-0 ">
        {/* begin::User */}
        <div
          // style={{marginRight:"40px"}}
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          {/* begin::Toggle */}
          <div
            className={clsx(
              "d-flex cursor-pointer symbol",
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <img src={toAbsoluteUrl("/images/no-user.jpeg")} alt="metronic" />
            <div className="usernameTopbar p-3">
              <h5 className="mb-0 pt-4">{user_full_name}</h5>
              <p>
                <small>{cookies.get("customer_code")}</small>
              </p>
            </div>
            <i className="bi bi-caret-down-fill fs-5"></i>
          </div>

          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {config.header.left === "menu" && (
          <div
            className="d-flex align-items-center d-lg-none ms-2 me-n3"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_header_menu_mobile_toggle"
            >
              <i className='fa fa-align-right fa-rotate-180 fs-1'></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Topbar };
