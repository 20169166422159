/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import "./footer.css"
const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <>
      <div className='footer py-2 d-flex flex-lg-column' id='kt_footer'>
        {/* begin::Container */}
        <div
          className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className='text-light order-2 order-md-1'>
          <span>Copyright LoopSafe Pty Ltd. LoopSafe Terms of Service found at <a href="https://www.loopsafe.net/terms-of-service" style={{textDecoration:"underline"}} target="_blank" rel="noreferrer">https://www.loopsafe.net/terms-of-service</a> and LoopSafe’s Privacy Policy found at <a href="https://www.loopsafe.net/privacy" target="_blank" style={{textDecoration:"underline"}} rel="noreferrer">https://www.loopsafe.net/privacy</a></span>
          </div>
          {/* end::Copyright */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Footer}
