import { FC, useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import CustomerDataContext from "../../../store/CustomerData-context";
import { Link, useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorMessage1 from "../../components/ErrorMessage1";
import {
  addUserHandler,
  siteAccessOptions,
  menuAccessOptions,
} from "./userManagement-hooks";
import Alert1 from "../../components/Alert1";
// for grabbing loginRole
// import { shallowEqual, useSelector } from "react-redux";
// import { RootState } from "../../../setup";
import ConstantsContext from "../../../store/Constants-context";
import Cookies from "universal-cookie";
import { Breadcrumb } from "../../../_metronic/layout/components/header/Breadcrumb";
import "./UserManagement.css";
const cookies = new Cookies();
const initialValues = {
  fName: "",
  lName: "",
  email: "",
  username: "",
  site_admin: false,
  // mobile: "",
};

const userDetailsSchema = Yup.object().shape({
  fName: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters allowed.")
    .required("First name is required"),
  lName: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters allowed.")
    .required("Last name is required"),
  email: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .email("Wrong email format")
    .min(3, "Minimum 3 characters required.")
    .max(50, "Maximum 50 characters required.")
    .required("Email is required"),
  username: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters allowed.")
    .required("Username is required"),
  // mobile: Yup.string()
  //   .trim("Cannot include leading and trailing spaces")
  //   .min(8, "Minimum 8 characters required.")
  //   .max(13, "Maximum 12 characters required."),
});

const AddUser: FC = () => {
  const history = useHistory();

  // const loginRole = useSelector<RootState>(
  //   ({ auth }) => auth.loginRole,
  //   shallowEqual
  // ); // grabbing login role from redux
  cookies.get("site_admin") !== "1" && window.location.replace("/"); // this page is only accessible to site_admins

  const [menuAccess, setMenuAccess] = useState<
    { value: string; label: string }[]
  >([]);
  const [previousMenuAccess, setPreviousMenuAccess] = useState<
      { value: string; label: string }[]
  >([]);
  const [menuAccessError, setMenuAccessError] = useState(false);
  const [siteAccess, setSiteAccess] = useState<
      { value: string; label: string }[]
  >([]);
  const [previousSiteAccess, setPreviousSiteAccess] = useState<
      { value: string; label: string }[]
  >([]);
  const [siteAccessError, setSiteAccessError] = useState(false);
  const [disableSiteAccess, setDisableSiteAccess] = useState(false);
  const [disableMenuAccess, setDisableMenuAccess] = useState(false);
  const [siteAdmin, setSiteAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const intl = useIntl();
  const customerCtx = useContext(CustomerDataContext);
  const constantsCtx = useContext(ConstantsContext);

  useEffect(() => {
    const siteAccessMultiSelectDropdown = document
        .getElementById("siteAccess")!
        .getElementsByClassName(
            "dropdown-container"
        ) as HTMLCollectionOf<HTMLElement>;
    const menuAccessMultiSelectDropdown = document
        .getElementById("menuAccess")!
        .getElementsByClassName(
            "dropdown-container"
        ) as HTMLCollectionOf<HTMLElement>;

    if (siteAdmin) {
      // Give all access to all sites
      customerCtx.sites.length > 0 && setSiteAccess(siteAccessOptions(customerCtx.sites));
      siteAccessMultiSelectDropdown[0].style.backgroundColor = "#f3f3f3";
      setDisableSiteAccess(true);
      setSiteAccessError(false);

      // Give all access to menu Options
      setMenuAccess(menuAccessOptions(customerCtx.menu_config));
      menuAccessMultiSelectDropdown[0].style.backgroundColor = "#f3f3f3";
      setDisableMenuAccess(true);
      // setMenuAccessError(false);
    } else {
      //Reset site access
      customerCtx.sites.length > 0 && setSiteAccess(previousSiteAccess);
      siteAccessMultiSelectDropdown[0].style.backgroundColor = "transparent";
      setDisableSiteAccess(false);
      //Reset menu access
      setMenuAccess(previousMenuAccess);
      menuAccessMultiSelectDropdown[0].style.backgroundColor = "transparent";
      setDisableMenuAccess(false);
    }
    // setMenuAccess(existingMenuOptions);
    // setMenuAccessError(false);
  }, [siteAdmin]);

  const formik = useFormik({
    initialValues,
    validationSchema: userDetailsSchema,
    onSubmit: async (values) => {
      await addUserHandler(
        values,
        setLoading,
        menuAccess,
        siteAccess,
        setSuccess,
        setError,
        setErrorMsg,
        history
      );
    },
  });

  const addUserBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: "MENU.SETUP" }),
      path: "/setup.html",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU.USERACCOUNTS" }),
      path: "/users",
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: "MENU.ADDUSERACCOUNT" }),
      path: "/adduser",
      isSeparator: false,
      isActive: true,
    },
  ];
  return (
    <>
      <Breadcrumb
        pageBreadcrumbs={addUserBreadCrumbs}
        activePageName={"user_accounts"}
      />
      {/* Setting Active Page Name in the Context */}
      <PageTitle activePageName={"user_accounts"}></PageTitle>
      {/* begin::Row */}
      <div className="row gy-5 gx-xl-8">
        <div className="col-xxl-12">
          <div className="card card-custom">
            {/* end::Header */}
            <div className="card-header">
              <div className="card-title">
                {intl.formatMessage({ id: "MENU.ADDUSERACCOUNT" })}
              </div>
            </div>
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let foundErrors = false;
                  if (siteAccess.length === 0) {
                    setSiteAccessError(true);
                    foundErrors = true;
                  }
                  // if (menuAccess.length === 0) {
                  //   setMenuAccessError(true);
                  //   foundErrors = true;
                  // }
                  if (foundErrors === false) formik.handleSubmit();
                }}
              >
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      First name <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.fName && formik.errors.fName,
                        },
                        {
                          "is-valid":
                            formik.touched.fName && !formik.errors.fName,
                        }
                      )}
                      {...formik.getFieldProps("fName")}
                    />
                    {formik.touched.fName && formik.errors.fName && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.fName}
                      />
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Last name <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.lName && formik.errors.lName,
                        },
                        {
                          "is-valid":
                            formik.touched.lName && !formik.errors.lName,
                        }
                      )}
                      {...formik.getFieldProps("lName")}
                    />
                    {formik.touched.lName && formik.errors.lName && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.lName}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Email <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="email"
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.email && formik.errors.email,
                        },
                        {
                          "is-valid":
                            formik.touched.email && !formik.errors.email,
                        }
                      )}
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.email}
                      />
                    )}
                  </div>

                  <div className="col-lg-6 col-xl-6">
                    <label className="col-lg-12 col-form-label">
                      Username <small style={{ color: "red" }}>*</small>
                    </label>
                    <input
                      type="text"
                      onFocus={(e) => {
                        formik.touched.email &&
                          !formik.errors.email &&
                          !formik.values.username &&
                          (e.target.value = formik.values.email) &&
                          (formik.values.username = formik.values.email);
                      }}
                      className={clsx(
                        "form-control form-control-solid px-5",
                        {
                          "is-invalid":
                            formik.touched.username && formik.errors.username,
                        },
                        {
                          "is-valid":
                            formik.touched.username && !formik.errors.username,
                        }
                      )}
                      {...formik.getFieldProps("username")}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={formik.errors.username}
                      />
                    )}
                  </div>
                </div>

                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    &nbsp;
                    {/*<label className="col-lg-12 col-form-label">*/}
                    {/*  Mobile*/}
                    {/*</label>*/}
                    {/*<input*/}
                    {/*  type="text"*/}
                    {/*  className={clsx(*/}
                    {/*    "form-control form-control-solid px-5",*/}
                    {/*    {*/}
                    {/*      "is-invalid":*/}
                    {/*        formik.touched.mobile && formik.errors.mobile,*/}
                    {/*    },*/}
                    {/*    {*/}
                    {/*      "is-valid":*/}
                    {/*        formik.touched.mobile && !formik.errors.mobile,*/}
                    {/*    }*/}
                    {/*  )}*/}
                    {/*  {...formik.getFieldProps("mobile")}*/}
                    {/*/>*/}
                    {/*{formik.touched.mobile && formik.errors.mobile && (*/}
                    {/*  <ErrorMessage1*/}
                    {/*    color={constantsCtx.ERROR_MESSAGE_COLOR_RED}*/}
                    {/*    errorMessage={formik.errors.mobile}*/}
                    {/*  />*/}
                    {/*)}*/}
                  </div>
                  <div className="col-lg-6 col-xl-6" id="menuAccess">
                    <label className="col-lg-12 col-form-label">
                      Menu Access Controls{" "}
                      {/*<small style={{ color: "red" }}>*</small>*/}
                    </label>
                    <MultiSelect
                      options={menuAccessOptions(customerCtx.menu_config)}
                      value={menuAccess}
                      disabled={disableMenuAccess}
                      hasSelectAll={true}
                      onChange={(e: any) => {
                        setMenuAccess(e);
                        // setMenuAccessError(false);
                        setPreviousMenuAccess(e);
                      }}
                      labelledBy="Select Menu Items"
                    />
                  </div>
                </div>

                <div className="row mb-10">
                  <div className="col-lg-4 col-xl-4">
                    <label className="form-check form-check-custom form-check-solid form-switch mb-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        {...formik.getFieldProps("site_admin")}
                        onClick={() => setSiteAdmin(!siteAdmin)}
                        checked={siteAdmin}
                      />
                      <span className="form-check-label col-form-label">
                        Site Administrator Access
                      </span>
                    </label>
                    <div className="d-flex p-1 mb-10" style={{ marginTop: "-2rem", marginLeft: "3.5rem"}}>
                      <div className="d-flex flex-column">
                        <span>Grants view/edit ability to all data, tools and settings</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-xl-2"></div>
                  <div className="col-lg-6 col-xl-6" id="siteAccess">
                    <label className="col-lg-12 col-form-label">
                      Site Access Controls{" "}
                      <small style={{ color: "red" }}>*</small>
                    </label>
                    <MultiSelect
                      options={siteAccessOptions(customerCtx.sites)}
                      value={siteAccess}
                      disabled={disableSiteAccess}
                      hasSelectAll={true}
                      onChange={(e: any) => {
                        setSiteAccess(e);
                        setSiteAccessError(false);
                        setPreviousSiteAccess(e);
                      }}
                      labelledBy="Select Sites"
                    />
                    {siteAccessError === true && (
                      <ErrorMessage1
                        color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                        errorMessage={"Please select at least one site"}
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-10">
                  {/* begin::Success Alert */}
                  {!loading && success && (
                    <Alert1
                      alertTitle="Success"
                      alertMessage="User Added"
                      success={true}
                    />
                  )}
                  {/* end::Success Alert */}

                  {/* begin::Failure Alert */}
                  {!loading && error && (
                    <Alert1
                      alertTitle={"Failure"}
                      alertMessage={errorMsg}
                      success={false}
                    />
                  )}
                  {/* end::Failure Alert */}
                </div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-xl-6">
                    <Link
                      className="btn btn-active-light btn-color-muted cancelButton"
                      to="/users"
                    >
                      Cancel
                    </Link>
                  </div>
                  <div className="col-lg-6 col-xl-6">
                    <button
                      type="submit"
                      className="btn btn-ll"
                      disabled={
                        formik.isSubmitting || !formik.isValid || success
                      }
                    >
                      {!loading && (
                        <span className="indicator-label">Save</span>
                      )}
                      {loading && (
                        <span className="indicator-progress">
                          Please wait...{" "}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AddUser };
