import * as Constants from '../constants';
import { queryStringGenerator, fixBlankToNull } from "./common.controller";

const peopleModel = {
    // "customer_code": null,
    // "people_id": null /*INDEX FIELD (CAN'T BE NULL)*/ ,
    "first_name": null,
    "last_name": null,
    "other_names": null,
    "email": "NONE" /*INDEX FIELD (CAN'T BE NULL)*/ ,
    "person_type": 'visitor',
    "year_level": 'NONE' /*INDEX FIELD (CAN'T BE NULL)*/ ,
    "is_delete": false,
    "terms_accepted": false,
    "home_level": 'NONE' /*INDEX FIELD (CAN'T BE NULL)*/ ,
    "user_name": null,
    "cognito_identity": "NONE",
    "visitor_type": null,
    "phone_number": null,
    "company": null,
    "house": null,
    "custom_profile": "{}",
    "working_with_children_check": null,
    "working_with_children_number": null,
    "can_schedule_visit": null,
    "collect_better_images": null,
    "facial_recognition_opt_out": false,
    "children": [],
    "dashboard_config": [],
    "collection_images": [],
    "employment_type": null,
    "documents": [],
    "requirement_checklist": [],
    "menu_config": [],
    "member_group": null,
    "staff_group": null
}


/**********************************************************************************************
 ░░░░ Anonymous functions  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 **********************************************************************************************/

// FUNCTION FOR GETTING USER PREFERENCES FROM LOCAL STORAGE TO PERSIST THEIR SELECTED FITLERS
// function getUserPreferences(page) {
//     let use_custom_settings = false
//     // REMOVE USER SETTINGS FROM LOCAL STORAGE - ONLY FOR TESTING. KEEP COMMENTED WHEN NOT IN USE
//     // localStorage.removeItem(page)

//     let user_settings = JSON.parse(localStorage.getItem(page))
//     if (!user_settings) {
//         // Default settings for pages
//         if (page === "visitor_attendance_user_settings") {
//             user_settings = {
//                 screening_switch: false,
//                 off_campus: false,
//                 scheduled_visit: false,
//                 visitor_filter: ['1', '2', '3', '4', '5', '6', '7', '12', '15', '16'],
//                 staff_filter: ['1', '2', '3', '4', '6', '8'],
//                 student_filter: ['1', '2', '3', '4', '6', '8'],
//                 guardian_filter: ['1', '2', '3', '4', '5', '6', '7', '12'],
//                 tblVisitors: {
//                     sorting: { 1: "desc", 5: "desc" },
//                 },
//                 tblStaff: {
//                     sorting: { 1: "desc", 6: "desc" },
//                 },
//                 tblGuardians: {
//                     sorting: { 1: "desc", 5: "desc" },
//                 },
//                 tblStudents: {
//                     sorting: { 1: "desc", 6: "desc" },
//                 },
//             }
//         } else {
//             user_settings = {
//                 tbl_visitor_schedule: {
//                     sorting: { 0: "asc", 4: "asc" },
//                 },
//                 room_segment: "%ALL",
//                 site_segment: "%ALL",
//                 site_switch: false,
//             }
//         }
//     } else {
//         use_custom_settings = true
//     }
//     return { "use_custom_settings": use_custom_settings, "user_settings": user_settings }
// }


// function getDatatableOrderingFromObject(obj) {
//     /* RETURNS AN ARRAY OF ARRAY/S FROM OBJECT FOR ORDERING IN DATATABLE
//      e.g RETURNS [[1,"asc"],[4,"desc"]] from {1: "asc" ,4:"asc"} */

//     let ordering = []
//     for (let key in obj) {
//         ordering.push([parseInt(key), obj[key]]);
//     }
//     return ordering
// }


/**********************************************************************************************
 ░░░░ User API calls ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 **********************************************************************************************/
/*
    USED TO FETCH PEOPLE RECORDS FROM DATABASE
    FUNCTION PARAMETERS : filters (OPTIONAL)
    USAGE
        -> {'persontype': 'student'}
        -> {'visitortype': 'contractor'}
        -> {'homelevel': '10'}
        -> {'yearlevel': '10'}
*/
export async function getPeopleV2(filters = {}) {
    /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
    let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
    const headers = Object.assign(Constants.commonHeaders, {});
    
    return await fetch(`${Constants.baseURL}${Constants.peopleAPI}${queryString}`, {
        headers,
        crossDomain: true
    }).then(response => response.json())    // json() is not a function so may not work
    .then(response => {
        return response.returnData;
    }).catch(error => {
        return error;
    });
}
// async function getPeopleV2Export(filters = {}) {
//     /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
//     let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
//     const headers = Object.assign(Constants.commonHeaders, {});

//     return new Promise((resolve, reject) => {
//         $.ajax({
//             type: 'GET',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}${queryString}`,
//             timeout: 29000, // sets timeout to 5000 = 5 seconds
//             retryCount: 0, // start retry count
//             retryLimit: 1, //will let you retry a determined number of times
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 if (error.statusText === 'timeout') {
//                     let date = moment().add(10, 'seconds')
//                     let id=Math.floor(Math.random() * 100000) + 1
//                     let data = {
//                        'todate': moment(date, 'X').utc().format(moment.defaultFormat),
//                        'title':'Whoops! The data request exceeded capacity. Try selecting a smaller date range and export again.',
//                        'id': id
//                     }
//                     localStorage.setItem('showerrorMessage',JSON.stringify(data))
//                     // showerrorMessage()
//                 }
//                 reject(error);
//                 // hideLoader()
//             },
//         });
//     });
// }

// async function getExportPeople(filters = {}) {
//     /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
//     let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
//     const headers = Object.assign(Constants.commonHeaders, {});
//     let exportAPI = Constants.peopleAPI + '/export';
//     console.log(`${Constants.baseURL}${exportAPI}${queryString}`)
//     return new Promise((resolve, reject) => {
//         $.ajax({
//             type: 'POST',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${exportAPI}${queryString}`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }

/* USED TO FETCH USER BY COGNITO IDENTITY */
// function getUserByCognitoIdentityV2(cognitoIdentity) {
//     return new Promise((resolve, reject) => {
//         let headers = Object.assign(Constants.commonHeaders, {});
//         $.ajax({
//             type: 'GET',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}${Constants.congnitoIdentityAPI}/${cognitoIdentity}`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }

/* USED TO FETCH USER BY PEOPLE ID */
// function getUserByPeopleIdV2(peopleId, filters, withProfile = false, customer_code = null) {
//     return new Promise(async (resolve, reject) => {
//         let headers = Object.assign(Constants.commonHeaders, {});
//         let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
        // $.ajax({
        //     type: 'GET',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}${queryString}`,
        //     success: (response) => {
        //         if (!Object.keys(response.returnData).length) {
        //             reject({ "responseJSON": { userMessage: "User not found" } });
        //         }

        //         response.returnData.profile_pic = '';
        //         if (response.returnData.collection_images && response.returnData.collection_images.length > 0) {
        //             let profilePic = response.returnData.collection_images[0];
        //             if (profilePic) {
        //                 getSignURL(profilePic.ObjectKey).then((imageData) => {
        //                     response.returnData.profile_pic = imageData;
        //                     resolve(response);
        //                 }).catch((err) => {
        //                     resolve(response);
        //                 });
        //             } else {
        //                 data.profile_pic = '';
        //                 resolve(response);
        //             }
        //         } else {
        //             resolve(response);
        //         }
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
//     });
// }

/* USED TO DELETE USER BY PEOPLE ID (APPLIED EVERYWHERE) */
// async function deleteUserV2(peopleId, filters = { site_id: $.cookie('activeSite') }) {
//     let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
//     return new Promise((resolve, reject) => {
//         let headers = Object.assign(Constants.commonHeaders, {});
//         $.ajax({
//             type: 'DELETE',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}${queryString}`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }

/*
    FUNCTION IS USED TO UPDATE USER DETAILS PARTIALLY OR FULLY
    PATCH : THIS METHOD WILL UPDATE ONLY PASSED PARAMETERS
    PUT : THIS METHOD WILL UPDATE ALL PARAMETERS (PARAMETERS WHICH NOT PASSED WILL BE STORED FROM DEFAULT)
*/
export async function updateUserV2(peopleId, peopleRecord, httpMethod = 'PATCH', filters, customHeaders = {}) {

    // return new Promise(async (resolve, reject) => {
    let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
    peopleRecord = await fixBlankToNull(peopleModel, peopleRecord);

    let headers = Object.assign(Constants.commonHeaders, customHeaders);

    delete peopleRecord.people_id;
    delete peopleRecord.customer_code;

    /* CONVERT EMAIL TO LOWERCASE */
    if (peopleRecord.email && peopleRecord.email !== 'NONE' && typeof peopleRecord.email !== 'undefined') {
        peopleRecord.email = peopleRecord.email.toLowerCase();
    }
    
    return fetch(`${Constants.baseURL}${Constants.peopleAPI}/${peopleId}${queryString}`, {
        method: httpMethod,
        headers,
        crossDomain: true,
        body: JSON.stringify(peopleRecord)
    })
    // });
}

/*
    FUNCTION IS USED TO STORE USER RECORD
*/
// function savePeopleV2(peopleRecords, httpMethod = 'POST', customHeaders = {}) {
//     const headers = Object.assign(Constants.commonHeaders, customHeaders);
//     return new Promise(async (resolve, reject) => {
         
//         peopleRecords.map(async (peopleRecord) => {
//             peopleRecord = await fixBlankToNull(peopleModel, peopleRecord);

//             // CONVERT EMAIL TO LOWERCASE
//             if (peopleRecord.email) {
//                 peopleRecord.email = peopleRecord.email.toLowerCase();
//             }
//         });
//         $.ajax({
//             method: httpMethod,
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}`,
//             data: JSON.stringify(peopleRecords),
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }


/*
    FUNCTION IS USED TO IMPORT USER RECORD
*/
// function importPeopleV2(peopleRecords, httpMethod = 'POST', customHeaders = {}) {
//     const headers = Object.assign(Constants.commonHeaders, customHeaders);
//     return new Promise(async (resolve, reject) => {
//         peopleRecords.customerSites.map((e)=>{ delete e.rooms;delete e.integriti_mappings;delete e.sftp_settings;});
//         peopleRecords.rawArr.map(async (peopleRecord) => {
//             // peopleRecord = await fixBlankToNull(peopleModel, peopleRecord.rowArr);
//             // CONVERT EMAIL TO LOWERCASE
//             if (peopleRecord.email) {
//                 peopleRecord.email = peopleRecord.email.toLowerCase();
//             }
//         });
//         $.ajax({
//             method: httpMethod,
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}/import`,
//             data: JSON.stringify(peopleRecords),
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }


// function updateUsersV2(peopleRecords, httpMethod = 'PUT') {
//     const headers = Object.assign(Constants.commonHeaders, { "Accept": "application/json" });

//     return new Promise(async (resolve, reject) => {
//         peopleRecords.map(async (peopleRecord) => {
//             peopleRecord = await fixBlankToNull(peopleModel, peopleRecord);

//             // CONVERT EMAIL TO LOWERCASE
//             if (peopleRecord.email && peopleRecord.email !== 'NONE' && peopleRecord.email !== 'none') {
//                 peopleRecord.email = peopleRecord.email.toLowerCase();
//             }
//         })
//         let data = [];
//         peopleRecords.forEach(function(record) {
            // let tempModel = _.clone(peopleModel);
            // record = { ...tempModel, ...record }
            // if (record.email && record.email !== 'NONE' && record.email !== 'none') {
            //     record.email = record.email.toLowerCase();
            // }
            // if (!record.visitor_type) {
            //     record.visitor_type = record.person_type;
            // }
            // delete record.customer_code;
            // record.collection_images = [];
            // data.push(record)
        // });

        // peopleRecords = data;

        // $.ajax({
        //     method: httpMethod,
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.peopleAPI}`,
        //     data: JSON.stringify(peopleRecords),
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
//     });
// }

/**********************************************************************************************
░░░░ Collection Images API calls ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
**********************************************************************************************/

/*
    1. Delete a collection image from a person record by face id
    2. Delete a collection image from a person record by object key
    (APPLIED EVERYWHERE)
*/
// function deleteCollectionImageByFaceIdV2(peopleId, faceId) {
//     return new Promise((resolve, reject) => {
//         const headers = Object.assign(Constants.commonHeaders, {});
//         $.ajax({
//             type: 'DELETE',
//             headers,
//             crossDomain: true,
//             dataType: 'json',
//             contentType: 'application/json',
//             url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}/collectionimages/${faceId}`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }

/*
    FUNCTION IS USED TO ADD COLLECTION IMAGE OF USER

    PARAMETERS:

    people_id : Unique id of user
    collection_images:[
        {
            "base64image": "<string>",
            "image_file_type": "<string>"
        }
    ]
*/

// function updateCollectionImages(peopleId, collectionImages, httpMethod = 'GET') {
//     const headers = Object.assign(Constants.commonHeaders, {});

//     return new Promise(async (resolve, reject) => {

//         $.ajax({
//             type: 'PATCH',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}${Constants.collectionImagesAPI}`,
//             data: JSON.stringify({ 'collection_images': collectionImages }),
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }


/**********************************************************************************************
 ░░░░ Anonymous API calls ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
 **********************************************************************************************/
/*
    function will check that if user with same people id exist in other site or not
    it will return true / false

    logic is used to hide / show cognito block in add user and edit user

*/
// function checkCognitoUserAllSites(peopleId) {
//     return new Promise((resolve, reject) => {
//         const headers = Object.assign(Constants.commonHeaders, {});
//         $.ajax({
//             type: 'GET',
//             headers,
//             crossDomain: true,
//             dataType: 'json',
//             contentType: 'application/json',
//             url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}/checkcognito`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }


/*
    1. Deletes user's photo id from database and s3 bucket
*/
// function removePhotoIdByPeopleId(peopleId) {
//     return new Promise((resolve, reject) => {
//         const headers = Object.assign(Constants.commonHeaders, {});
//         $.ajax({
//             type: 'DELETE',
//             headers,
//             crossDomain: true,
//             dataType: 'json',
//             contentType: 'application/json',
//             url: `${Constants.baseURL}${Constants.peopleAPI}/${peopleId}/photoid`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }

// async function getPeopleByCustomer(customerCode, filters = {}) {
//     /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
//     let queryString = await queryStringGenerator(filters, Constants.queryParams.people);
//     const headers = Object.assign(Constants.commonHeaders, {});

//     return new Promise((resolve, reject) => {
//         $.ajax({
//             type: 'GET',
//             crossDomain: true,
//             headers,
//             url: `${Constants.baseURL}${Constants.peopleAPI}/customer/${customerCode}${queryString}`,
//             success: (response) => {
//                 resolve(response);
//             },
//             error: (error) => {
//                 reject(error);
//             },
//         });
//     });
// }