import React, { useEffect, useContext, useState } from "react";
import ConstantsContext from "../../store/Constants-context";

const ErrorMessage1 = (props: { color: string; errorMessage: string }) => {
  const constantsCtx = useContext(ConstantsContext);
  const [color, setColor] = useState(constantsCtx.ERROR_MESSAGE_COLOR_DEFAULT);

  useEffect(() => {
    if (props.color) {
      setColor(props.color);
    }
  }, [props.color]);

  return (
    <div className="fv-plugins-message-container">
      <div className="fv-help-block">
        <span role="alert" style={{ color }}>
          {props.errorMessage}
        </span>
      </div>
    </div>
  );
};

export default ErrorMessage1;
