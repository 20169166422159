import React, { useContext, useEffect, useState } from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider } from "./core";
import { NotificationWidget } from '../../app/UI';
import { getCustomerData } from "../../hooks/common";
import CustomerDataContext from "../../store/CustomerData-context";
import UserInfoContext from "../../store/UserInfo-context";
import AuthContext from "../../store/Auth-context";
import "./MasterLayout.css"
// import { shallowEqual, useSelector } from "react-redux";
// import { RootState } from "../../setup";

let counterTimer: ReturnType<typeof setTimeout>;

const MasterLayout: React.FC = ({ children }) => {
  const customerCtx = useContext(CustomerDataContext);
  const userInfoCtx = useContext(UserInfoContext)
  const AuthCtx = useContext(AuthContext);
  const isLoggedIn = AuthCtx.isLoggedIn;
  const [counter, setCounter] = useState(AuthCtx.config.warning * 60);
  // const loginRole = useSelector<RootState>(
  //   ({ auth }) => auth.loginRole,
  //   shallowEqual
  // ); // grabbing login role from redux
  useEffect(() => {
    const getCustomerInfo = async () => {
      try {
        await getCustomerData()
        .then((res) => res.json())
        .then((response) => {
          let data = response.returnData;
          /* check if the data is undefined
          when the authToken expires, the API response will not have returnData,
          which make data undefined. If data is undefined, redirect the user to oauth.html
          */
          (data === undefined) && AuthCtx.logoutCtx()
          customerCtx.setCustomerDataCtx(data);
          customerCtx.setLicenseCtx(data.customer_license);
          customerCtx.setCustomerTypeCtx(data.customer_type);
          customerCtx.setMemberNameCtx(data.member_name);
          customerCtx.setMenuConfigCtx(data.menu_config);
          customerCtx.setSiteCtx(data.sites);
          customerCtx.setApiCallStatusCtx(true);
        })
        .catch((error) => {
          console.error(error);
        });
      } catch (err) {
        console.error(err);
      }
    };
    getCustomerInfo();

    // setting all the authentication cookie values set via oauth.html into a context

  }, [userInfoCtx.activeSite, customerCtx.status]);

  useEffect(() => {
    if(AuthCtx.displayWarning) {
      counter > 0 && (counterTimer = setTimeout(() => setCounter(counter - 1), 1000))
    } else {
      clearTimeout(counterTimer);
      setCounter(AuthCtx.config.warning * 60)
    }
  }, [AuthCtx.displayWarning, counter]);

  window.onload = AuthCtx.resetTimer; /* Window is refreshed. */
  window.onmousemove = AuthCtx.resetTimer; /* Mouse is moved. */
  window.onkeypress = AuthCtx.resetTimer; /* Key is pressed. */
  window.onmousedown = AuthCtx.resetTimer; /* Touchscreen is pressed. */
  window.onclick = AuthCtx.resetTimer; /* Touchpad clicks. */
  window.onscroll = AuthCtx.resetTimer; /* Scrolling with arrow keys. */
  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <HeaderWrapper />
          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            {/* <Toolbar /> */}
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Content>
                {!customerCtx.status && <NotificationWidget notification="loading..." notificationType="info" downloadLink='' ctaButtonMessage="" dispCloseButton={false} closeBtnAction={() => {}} />}
                {customerCtx.status && children}
              </Content>
            </div>
          </div>
          <Footer />
        </div>
        
        {isLoggedIn && AuthCtx.displayWarning && <div id="sessExpirDiv" style={{opacity:"1",visibility:"visible",display:"block"}}><b><p id="sessExpirP">Due to inactivity your login session will expire. Use keyboard or mouse to remain logged in. {counter} seconds remaining</p></b></div>}
      </div>

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
