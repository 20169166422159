import moment from 'moment-timezone';
import * as Constants from '../constants';
import {getAttendanceLogs} from './common'
import { exportCSVFile } from './custom'
import {nameGenerator} from './utils'
import Cookies from "universal-cookie";
const cookies = new Cookies();

/*
	Report Description

	1. Sign in logs which have sign in details only i.e signed in & no signed out
	2. Sign in records with status approved
	3. Sign in reords which are on site(site) i.e not other location (internal_location).

*/
export async function emergencyEvacuation() {
	let headers = {};
    let exportData = [];
    let fileName = '';
    let attendanceRecords = [];
    fileName = `Emergency_Evacuation_Report_${moment().format('X')}`;

    return await getAttendanceLogs({
        fromDate: `${moment().format('YYYY-MM-DD')}T00:00:00${Constants.tzOffset}`,
        toDate: `${moment().format('YYYY-MM-DD')}T23:59:00${Constants.tzOffset}`
    }).then(res => res.json())
    .then(data => {
        attendanceRecords = data.returnData.sort((a, b) => a.time_in > b.time_in ? 1 : -1).reverse();
        attendanceRecords = attendanceRecords.filter((rec) => {
            return !(rec.time_out);
        });
        attendanceRecords = attendanceRecords.filter((rec) => {
            return rec.sign_in_status !== 'blocked'
        });
        attendanceRecords = attendanceRecords.filter((rec) => { 
            return rec.sign_in_mode === 'site' && rec.site_id === cookies.get('activeSite')
        });
        /*GENERATING ARRAY WITH FIELDS REQUIRED IN CSV*/
        attendanceRecords.forEach((attendance) => {
            let record = attendanceRecords.filter((rec) => {
                return rec.people_id === attendance.people_id
            })
            record = record.sort((a, b) => a.time_in > b.time_in ? 1 : -1).reverse();
            let last_seen_location = record && record.length && record[0].location ? record[0].location : "N/A";

            let person_type;
            if (attendance.person_type === 'teacher') {
                person_type = 'staff'
            } else if (attendance.person_type === 'student') {
                person_type = Constants.MEMBER_NAME;
            } else {
                person_type = attendance.person_type;
            }
            exportData.push({
                user_type: person_type,
                ID: attendance.people_id,
                first_name: attendance.first_name,
                last_name: attendance.last_name,
                sign_time: moment.parseZone(attendance.time_in, moment.defaultFormat).format('HH:mm A'),
                last_seen_location,
                last_seen_time: attendance.time_in ? moment.parseZone(attendance.time_in, moment.defaultFormat).format('HH:mm A') : 'N/A',
                email: attendance.email ? attendance.email : '',
                phone: attendance.phone_number && attendance.phone_number !== "N/A" ? attendance.phone_number : '',
                mobile_number: attendance.mobile_number && attendance.mobile_number !== "N/A" ? attendance.mobile_number : '',
                company_name: attendance.company_name && attendance.company_name !== "N/A" ? attendance.company_name : '',
                host: nameGenerator(attendance.who_seeing_first_name, attendance.who_seeing_last_name, attendance.who_seeing_people_id),
                dependents: attendance.dependents ? attendance.dependents : '',
            });
            /*CSV COLUMN HEADER*/
            headers = {
                user_type: 'User Type',
                ID: 'ID',
                first_name: 'First Name',
                last_name: 'Last Name',
                sign_time: 'Sign In Time',
                last_seen_location: 'Last Seen Location',
                last_seen_time: 'Last Seen Time',
                email: 'Email',
                phone: 'Phone',
                mobile_number: 'Mobile',
                company_name: 'Company',
                host: 'Host',
                dependents: 'Number of Dependents'
            };

        });
        if(attendanceRecords.length === 0) { console.log('No Data Available!'); return false; } else {
            console.log(attendanceRecords)
        }
        if (exportData && exportData.length) {
            console.log(`exporting CSV File...`)
            exportCSVFile(headers, exportData, fileName);
        } else {
            console.log('No records to export..')
        }
    }).catch(error => {
        console.log(error)
    });
}