import React, {useState} from 'react'
import Cookies from 'universal-cookie/es6';
const cookies = new Cookies();

/*
Context for handling all of logged in user's info like

activeSite
email
is_2fa_enabled
master_site_id
menu_config
people_id
profilephoto
user_full_name
user_menu_config
user_name

*/

const UserInfoContext = React.createContext({
    activeSite: '',
    email: '',
    is2faEnabled: '',
    masterSiteID: '',
    menuConfig: '',
    peopleID: '',
    profilePhoto: '',
    userFullName: '',
    userMenuConfig: '',
    username: '',
    setActiveSite: (x: string) => {},
    setEmail: (x: string) => {},
    setIs2faEnabled: (x: string) => {},
    setMasterSiteID: (x: string) => {},
    setMenuConfig: (x: string) => {},
    setPeopleID: (x: string) => {},
    setProfilePhoto: (x: string) => {},
    setUserFullName: (x: string) => {},
    setUserMenuConfig: (x: string) => {},
    setUsername: (username: string) => {},
});

export const UserInfoContextProvider: React.FC = ({children}) => {
    const [activeSite, setActiveSite] = useState(cookies.get('activeSite'));
    const [email, setEmail] = useState(cookies.get('email'));
    const [is2faEnabled, setIs2faEnabled] = useState(cookies.get('is_2fa_enabled'));
    const [masterSiteID, setMasterSiteID] = useState(cookies.get('master_site_id'));
    const [menuConfig, setMenuConfig] = useState(cookies.get('menu_config'));
    const [peopleID, setPeopleID] = useState(cookies.get('people_id'));
    const [profilePhoto, setProfilePhoto] = useState(cookies.get('profilephoto'));
    const [userFullName, setUserFullName] = useState(cookies.get('user_full_name'));
    const [userMenuConfig, setUserMenuConfig] = useState(cookies.get('user_menu_config'));
    const [username, setUsername] = useState(cookies.get('user_name'));

    // cookies.remove('activeSite')
    // cookies.remove('email')
    // cookies.remove('is_2fa_enabled')
    // cookies.remove('master_site_id')
    // cookies.remove('menu_config')
    // cookies.remove('people_id') 
    // cookies.remove('profilephoto')
    // cookies.remove('user_full_name')
    // cookies.remove('user_menu_config')
    // cookies.remove('user_name')
    
    const contextvalue = {
        activeSite,
        email,
        is2faEnabled,
        masterSiteID,
        menuConfig,
        peopleID,
        profilePhoto,
        userFullName,
        userMenuConfig,
        username,
        setActiveSite,
        setEmail,
        setIs2faEnabled,
        setMasterSiteID,
        setMenuConfig,
        setPeopleID,
        setProfilePhoto,
        setUserFullName,
        setUserMenuConfig,
        setUsername
    };

    return (<UserInfoContext.Provider value={contextvalue}>
        {children}
    </UserInfoContext.Provider>);
}

export default UserInfoContext;