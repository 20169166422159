import React from 'react';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getDomain = (url: any, subdomain: string | boolean) => {
    subdomain = subdomain || false;
    url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    if (!subdomain) {
        url = url.split('.');
        url = url.slice(url.length - 2).join('.');
    }
    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }
    return url;
}

const getAliasFromSubDomain = (subdomain: string, environment = "default") => {
    const subDomainArr = subdomain.split('.');    
    const alias = subDomainArr[0];
    if (alias === 'app') {
        return environment;
    }
    return alias;
}

const subdomain = getDomain(window.location.href, true);
const ipAddressRegx = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$');
// const cTimeZoneOffset : cookies.get('gmtAdjustment');
const cTimeZoneOffset = cookies.get('gmtAdjustment') ? cookies.get('gmtAdjustment') : '+11:00'; // JUST FOR DEV PURPOSES
const config = {
    'validation': 'All fields with * are required',
    'time_out': 1500,
    'redirect_time_out': 1500,
    'ipAddressRegx': ipAddressRegx,
    'isodate_format': 'YYYY-MM-DDTHH:mm:ssZ',
    'date_format': 'DD MMM YYYY',
    'default_timezone_offset': '+10:00',
    'longer_time_out':4000,
};

const AUTH_TOKEN = cookies.get('authToken');

const LICENSES = {
    'PRO': { id: 'loopkiosk_pro', name: 'LoopKiosk Pro'},
    'STANDALONE': { id: 'loopkiosk_standalone', name: 'LoopKiosk Standalone'}
}

const URL_ENV = window.location.hostname.split(".")[0] || "dev2";
const ENV = (URL_ENV === "localhost") ? "dev2" : (URL_ENV === "app") ? "prod" : URL_ENV;

const ConstantsContext = React.createContext({
    subdomain,
    apinewurl : '',
    baseURL : '',
    getUrl : window.location,
    basepath : '',
    basepathAdmin : '',
    loginUrl : '',
    logoutUrl : '',
    cCode : '',
    cRegion : '',
    cTimezone : '',
    AUTH_TOKEN: '',
    CUSTOMER_TYPE : '',
    MEMBER_NAME : '',
    SITE_NAME : '',
    MEMBER_FIELD : '',
    nif : '',
    nuf : '',
    UserPoolId : '',
    ClientId : '',
    ipAddressRegx: {},
    cTimeZoneOffset,
    config: {},
    routesConfig : {},
    tzOffset : '',
    apiURL : '',
    peopleAPI : '',
    congnitoIdentityAPI : '',
    unassignedDevicesAPI : '',
    visitorAttendanceAPI : '',
    exportSignInLogsPath : '',
    visitorScheduleAPI : '',
    collectionImagesAPI : '',
    unidentifiedPeopleAPI : '',
    customerSettingsAPI : '',
    peoplegroupauditAPI : '',
    deviceGroupsAPI : '',
    devicesAPI : '',
    versionsAPI : '',
    peopleGroupsAPI : '',
    visitorTypesAPI : '',
    memberGroupsAPI : '',
    staffGroupsAPI : '',
    imagesToDeleteAPI : '',
    companiesAPI : '',
    companyGroupAPI : '',
    apikeyAPI : '',
    queryParams : {},
    commonHeaders : {},
    headersSuperAdmin : {},
    URL_REGEX : /^/,
    EMAIL_REGEX : /^/,
    PEOPLE_ID_REGEX : /^/,
    USERNAME_REGEX : /^/,
    MOBILE_REGEX : /^/,
    DEFAULT_STAFF_GROUP_SCHEMA : {},
    LICENSES,
    URL_ENV,
    ENV,
    ERROR_MESSAGE_COLOR_DEFAULT: '',
    ERROR_MESSAGE_COLOR_RED: ''
});

export const ConstantsContextProvider: React.FC = ({children}) => {

    const contextvalue = {
        subdomain,
        apinewurl : 'https://j7wii6a6u5.execute-api.ap-southeast-2.amazonaws.com/' + getAliasFromSubDomain(subdomain),
        baseURL : 'https://467xh4opj1.execute-api.ap-southeast-2.amazonaws.com/' + getAliasFromSubDomain(subdomain, 'prod'),
        getUrl : window.location,
        basepath : 'https://' + subdomain + '/',
        basepathAdmin : 'https://' + subdomain + '/admin/',
        loginUrl : `https://auth.looplearn.live/login?response_type=code&client_id=74ggjm4mbp4o75pn6sbasst72r&redirect_uri=https://${subdomain}/oauth.html&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile`,
        logoutUrl : `https://auth.looplearn.live/logout?response_type=code&client_id=74ggjm4mbp4o75pn6sbasst72r&redirect_uri=https://${subdomain}/oauth.html&state=STATE&scope=aws.cognito.signin.user.admin+email+openid+phone+profile`,
        cCode : cookies.get('customer_code'),
        cRegion : cookies.get('region'),
        cTimezone : cookies.get('timezone'),
        AUTH_TOKEN,
        CUSTOMER_TYPE : cookies.get('customerType') ? cookies.get('customerType') : 'education',
        MEMBER_NAME : cookies.get('memberName') ? cookies.get('memberName').charAt(0).toUpperCase() + cookies.get('memberName').toLowerCase().slice(1) : 'Users',
        SITE_NAME : cookies.get('siteName') ? cookies.get('siteName').charAt(0).toUpperCase() + cookies.get('siteName').toLowerCase().slice(1) : 'Site',
        MEMBER_FIELD : cookies.get('memberField') ? cookies.get('memberField') : 'Users',
        nif : 'images/nif.png',
        nuf : 'images/no-user.jpeg',
        UserPoolId : 'ap-southeast-2_mSfh75PRx',
        ClientId : '74ggjm4mbp4o75pn6sbasst72r',
        ipAddressRegx,
        cTimeZoneOffset,
        config,
        routesConfig : {
            'member_attendance': ['/students_results.html'],
            'location_attendance': ['/students_room_rolls.html'],
            'unverified_people': ['/unidentified_people.html'],
            'sign_in_logs': ['/visitor_attendance.html', '/sign_in_user.html', '/visit_details.html'],
            'staff_attendance': ['/staff_attendance.html'],
            'visitor_schedule': ['/visitor_schedule.html', '/schedule_visit.html', '/edit_scheduled_visit.html', '/add_user.html', '/edit_user.html', '/edit_visitor.html'],
            'companies': ['/companies.html', '/add_company.html', '/add_company_group.html', '/edit_company.html', '/edit_company_group.html'],
            'people_list': ['/people.html', '/edit_user.html', '/add_user.html', '/add_student.html', '/bulk_edit_users.html'],
            'setup': [
                '/setup.html',
                '/rooms.html',
                '/edit_room.html',
                '/add_room.html',
                '/periods.html',
                '/add_period.html',
                '/edit_period.html',
                '/devices.html',
                '/bulk_edit_devices.html',
                '/edit_device_v1.html',
                '/edit_sensor.html',
                '/visitor_management.html',
                '/edit_visitor_type.html',
                '/importer.html',
                '/templates.html',
                '/add_member_group.html',
                '/edit_member_group.html',
                '/add_staff_group.html',
                '/edit_staff_group.html',
                '/edit_visitor.html',
                '/sites.html',
                '/add_site.html',
                '/edit_site.html',
                '/integriti_setup.html',
                '/api_settings.html'
            ],
        },
        tzOffset : cTimeZoneOffset ? cTimeZoneOffset : config.default_timezone_offset,
        apiURL : `https://467xh4opj1.execute-api.ap-southeast-2.amazonaws.com/dev/`,
        peopleAPI : '/people',
        congnitoIdentityAPI : '/cognitoidentity',
        unassignedDevicesAPI : '/unassigneddevices',
        visitorAttendanceAPI : '/visitorattendance',
        exportSignInLogsPath : '/visitorattendance/export',
        visitorScheduleAPI : '/visitorschedule',
        collectionImagesAPI : '/collectionimages',
        unidentifiedPeopleAPI : '/unidentifiedpeople',
        customerSettingsAPI : '/customersettings',
        peoplegroupauditAPI : '/peoplegroupaudit',
        deviceGroupsAPI : '/devicegroups',
        devicesAPI : '/devices',
        versionsAPI : '/versions',
        peopleGroupsAPI : '/peoplegroups',
        visitorTypesAPI : '/visitortypes',
        memberGroupsAPI : '/membergroups',
        staffGroupsAPI : '/staffgroups',
        imagesToDeleteAPI : '/imagestodelete',
        companiesAPI : '/companies',
        companyGroupAPI : '/companygroups',
        apikeyAPI : '/apikeys',
        queryParams : {
            people: ['year_level', 'home_level', 'person_type', 'visitor_type', 'return_only', 'site_id'],
            unassigned_devices: ['device_type'],
            visitor_attendance: ['time_in', 'people_id', 'stillhere', 'person_type'],
            visitor_schedule: ['timein', 'email'],
            unidentified_people: ['date_added', 'room_name', 'site_id'],
            people_group_audit: ['created_at', 'updated_at', 'group_name', 'group_type'],
            devices : ['device_type','is_activated','return_only'],
            images_to_delete : ['people_id'],
            company : []
        },
        commonHeaders : {
            Authorization: cookies.get('authToken') ? cookies.get('authToken') : AUTH_TOKEN,
            'Content-Type': 'application/json',
        },
        headersSuperAdmin : {
            Authorization: AUTH_TOKEN,
            'Content-Type': 'application/json',
        },
        URL_REGEX : /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        EMAIL_REGEX : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        PEOPLE_ID_REGEX : /^[a-zA-Z0-9_.\-:]+$/,
        USERNAME_REGEX : /^\S*$/,
        // const MOBILE_REGEX: /^\+?[1-9]\d{1,14}$/; This one failed in some cases. So using below one. To be reviewed by Krunal
        MOBILE_REGEX : /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/,
        DEFAULT_STAFF_GROUP_SCHEMA : {"staff_custom_details":{},"screening_questions":{"questionnaire":[],"failure_message":"Please see a staff member to discuss your screening question responses."},"requirement_checklist":[],"documents":[],"name":"","message":"Please see a staff member to discuss your screening question responses.","email_config":{"to_staff":{"blocked_sign_in_screening_receipients":[],"temperature_blocked_recipients":[],"temperature_blocked":false,"document_checklist_blocked":false,"document_checklist_blocked_recipients":[],"blocked_sign_in_screening":false}},"sms_config":{"to_staff":{"denied_entry_alert":false,"denied_entry_alert_receipients":[]}}},
        LICENSES : {
            'PRO': { id: 'loopkiosk_pro', name: 'LoopKiosk Pro'},
            'STANDALONE': { id: 'loopkiosk_standalone', name: 'LoopKiosk Standalone'}
        },
        URL_ENV,
        ENV,
        // Colors
        ERROR_MESSAGE_COLOR_DEFAULT : "#181c32",
        ERROR_MESSAGE_COLOR_RED : "#f1416c",
    };

    return (<ConstantsContext.Provider value={contextvalue}>
        {children}
    </ConstantsContext.Provider>);
}

export default ConstantsContext;