import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import { AuthContextProvider } from '../store/Auth-context'
import { ConstantsContextProvider } from '../store/Constants-context'
import { CustomerDataContextProvider } from '../store/CustomerData-context'
import { UserInfoContextProvider } from '../store/UserInfo-context'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <ConstantsContextProvider>
              <AuthContextProvider>
                <CustomerDataContextProvider>
                  <UserInfoContextProvider>
                    <AuthInit>
                      <Routes />
                    </AuthInit>
                  </UserInfoContextProvider>
                </CustomerDataContextProvider>
              </AuthContextProvider>
            </ConstantsContextProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
