import {FC, useContext, useState, useEffect } from 'react'
import UserInfoContext from "../../../../store/UserInfo-context";
import ConstantsContext from '../../../../store/Constants-context';
import { getScheduledVisits, UpcomingScheduledVisitsRecords, UpcomingScheduledVisits_Records } from "../dashboard-hooks";


const UpcomingScheduledVisits: FC<{startDate: Date, signInLogs: any}> = ({startDate, signInLogs = []}) => {
    const userInfoCtx = useContext(UserInfoContext)
    const constantsCtx = useContext(ConstantsContext)
    const [scheduledVisits, setScheduledVisits] = useState<string[]>([]);
    const [scheduledVisitCount, setScheduledVisitCount] = useState(0);
    const [scheduledVisitRecords, setScheduledVisitRecords] = useState<UpcomingScheduledVisits_Records[]>([]);
    useEffect(() => {
        getScheduledVisits(startDate, constantsCtx, userInfoCtx, setScheduledVisits);
    }, [startDate])

    useEffect(() => {
        UpcomingScheduledVisitsRecords(scheduledVisits, signInLogs, setScheduledVisitCount, setScheduledVisitRecords);
    }, [scheduledVisits])

    return (
        <div className="col-xl-8 upcomingScheduledVisits">
            <div className="card card-dashboard card-stretch">
                {/* begin::Body */}
                <div className='card-body py-9'>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-muted py-3">Upcoming Scheduled Visits :</h2>
                        </div>
                        <div className="col-md-4 align-items-center d-flex flex-grow-1 justify-content-center m-b-0 m-t-0">
                            <h1>{scheduledVisitCount}</h1>
                        </div>
                        <div className="col-md-8 text-center table-responsive">
                            <table className="no-footer table align-middle gs-0 gy-5" id="tbl_scheduled_visit">
                            { scheduledVisitCount === 0 && <tbody id="tbl_scheduled_visit_body text-center"><tr key={'noRecords'}><td className="noRecords">No records available...</td></tr></tbody>}
                            { scheduledVisitCount > 0 && 
                                <>
                                    <thead>
                                        <tr style={{border:"none"}} key={'upcomingScheduledVisits'}>
                                            <th><b>Name</b></th>
                                            <th><b>Host</b></th>
                                            <th><b>Arrival Time</b></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {scheduledVisitRecords.map((record: UpcomingScheduledVisits_Records) => 
                                        <tr key={record.schedule_id}>
                                            <td>{record.full_name}</td>
                                            <td>{record.host_name}</td>
                                            <td>{record.expected_time_in}</td>
                                            <td>
                                                <a style={{cursor:"pointer"}} href={`edit_scheduled_visit.html?id=${record.schedule_id}`}>
                                                    <i className="bi bi-chevron-right fs-1"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </>
                            }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {UpcomingScheduledVisits};