/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { endSession } from "../../../../hooks/common"
import "./HeaderUserMenu.css";

const HeaderUserMenu: FC = () => {
  const logout = () => {
    endSession()
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-250px'
      data-kt-menu='true'>
      <div className='menu-item px-5'>
        <a href='/profile.html' className='menu-link px-5'>
          <p className="m-0"><i className="bi bi-person-fill fs-1"></i></p>
          <p className="m-0" style={{paddingLeft:"20px"}}>My Profile</p>
        </a>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <p className="m-0"><i className="bi bi-box-arrow-right fs-1"></i></p>
          <p className="m-0" style={{paddingLeft:"20px"}}>Sign Out</p>
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
