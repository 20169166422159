import clsx from 'clsx'
import React, {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../core/LayoutProvider'

const Breadcrumb: FC<{pageBreadcrumbs: any, activePageName: any}> = ({pageBreadcrumbs, activePageName}) => {
  useEffect(() => {
    // Setting page title on browser tab
    document.title = "LoopSafe | " + activePageName.split("_").map((word: string) => {
      return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
    }).join(" ");
  }, [activePageName])
  const {classes} = useLayout()
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title pt-2 pb-1 d-flex', classes.pageTitle.join(' '))}
    >
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 && (
          <>
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item:any, index) => (
                <li style={{paddingRight:"0rem"}}
                  className={clsx('breadcrumb-item', {
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? 
                    <Link className='text-primary' to={item.path}>
                      {item.title}
                    </Link>
                  : 
                    <>
                      {!item.path.includes(".html") ?
                        <Link className='text-muted text-hover-primary' to={item.path}>
                          {item.title}
                        </Link>
                      : <a className='text-muted text-hover-primary' href={item.path}>{item.title}</a>}
                      {!item.isActive && <i className="bi bi-caret-right-fill fs-6 px-2 py-1" style={{color:"#a1a5b7"}}></i>}
                    </>
                  }
                </li>
              ))}
            </ul>
          </>
        )}
    </div>
  )
}

export { Breadcrumb }
