import React, { useState } from 'react';
import Cookies from "universal-cookie";
const cookies = new Cookies();

let logoutTimer: ReturnType<typeof setTimeout>, warningTimer: ReturnType<typeof setTimeout>;
/* COMPATIBILITY WITH JQUERY TIMEOUT*/
let activeTime = Date.now(); /* Milliseconds since 1970/01/01. */

let config = {
    autologout: 30,
    warning: 2
}

const calculateRemainingTime = (time: string) => {
    const current = new Date().getTime();
    const adjusted = new Date(time).getTime();
    const remaining = adjusted - current;
    return remaining
}

const AuthContext = React.createContext({
    accessToken: '',
    authToken: '',
    cognito: '',
    activeSite: '',
    customerCode: '',
    customerType: '',
    
    isLoggedIn: false,
    displayWarning: false,
    config,
    loginCtx: (x: string) => {},
    logoutCtx: () => {},
    resetTimer: () => {},

    setAccessTokenCtx: (x: string) => {},
    setAuthTokenCtx: (x: string) => {},
    setCognitoCtx: (x: string) => {},
    setActiveSiteCtx: (x: string) => {},
    setCustomerCodeCtx: (x: string) => {},
    setCustomerTypeCtx: (x: string) => {},
});

export const AuthContextProvider: React.FC = ({children}) => {
    const [accessToken, setAccessToken] = useState(cookies.get('access_token'));
    const [cognito, setCognito] = useState(cookies.get('cognito_identity'));
    const [activeSite, setActiveSite] = useState(cookies.get('activeSite'));
    const [customerCode, setCustomerCode] = useState(cookies.get('customer_code'));
    const [customerType, setCustomerType] = useState(cookies.get('customerType'));


    const [authToken, setAuthToken] = useState(cookies.get('authToken'));
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [displayWarning, setDisplayWarning] = useState(false)
    const userIsLoggedIn = !!authToken;

    const logoutHandler = () => {
        console.log("LOGGING OUT USER")
        setAuthToken(null)
        setIsLoggedIn(false)
        cookies.remove('authToken')
        localStorage.removeItem('expTime');
        localStorage.removeItem('warningTime');
        if(logoutTimer) {
            clearTimeout(logoutTimer)
        }
        !isLoggedIn && window.location.replace("/");
    };

    const warningHandler = () => {
        if(authToken){
            if(new Date().getTime() > new Date(localStorage.getItem('warningTime') || '').getTime()){
                // console.log("DISPLAY WARNING!!!")
                setDisplayWarning(true)

                /* Now we check that no other tab has been active after us.
                let browserActive = parseInt(localStorage.getItem('activeTime') || '');
                console.log("activeTime >>>", activeTime);
                console.log("browserActive >>>", browserActive);
                if (activeTime !== browserActive) { /* Then another tab has been active more recently than this tab. */
                    // alert("Not the same. User has been active in another tab. browserActive: " + browserActive + " and activeTime: " + activeTime);
                    /* We want to keep going, because user might close the other tab - and if this script is broken, the sessionExpiration is broken. */
                    // sessionExpiration(idleMinInput, warningMinInput, logoutUrl);
                    // recordTime() // this is the function that sets the activeTime to prolong the session if multiple tabs are open
                // } */
            }
        }
    };

    function recordTime() {
        /* COMPATIBILITY WITH JQUERY TIMEOUT*/
        activeTime = Date.now()
        localStorage.setItem('activeTime', activeTime.toString());
    }

    const setupTimes = () => {
        recordTime();
        let expTime: Date | string = new Date(new Date().getTime() + (config.autologout * 60 * 1000)) // autologout after X mins
        const warningTime = new Date(expTime.getTime() - (config.warning * 60 * 1000)).toISOString() // warning Y mins before logout
        expTime = expTime.toISOString()
        localStorage.setItem('expTime', expTime)
        localStorage.setItem('warningTime', warningTime)

        // console.log("calculateRemainingTime(expTime) >>", calculateRemainingTime(expTime))
        // console.log("calculateRemainingTime(warningTime) >>", calculateRemainingTime(warningTime))
        logoutTimer = setTimeout(logoutHandler, calculateRemainingTime(expTime));
        warningTimer = setTimeout(warningHandler, calculateRemainingTime(warningTime));
        // console.log("logoutTimer >>>", logoutTimer)
        // console.log("warningTimer >>>", warningTimer)
    }


    const loginHandler = (authToken: string) => {
        setAuthToken(authToken);
        cookies.set('authToken', authToken);
        setupTimes();
    };

    const resetTimer = () => {
        if(authToken){
            // if there is a valid authToken, do the below
            clearTimeout(logoutTimer)
            clearTimeout(warningTimer)
            setupTimes();
            displayWarning && setDisplayWarning(false)
            // console.log("COPY THAT!", displayWarning)
        }
    }


    const contextvalue = {
        accessToken,
        authToken,
        cognito,
        activeSite,
        customerCode,
        customerType,

        isLoggedIn: userIsLoggedIn,
        displayWarning,
        config,
        loginCtx: loginHandler,
        logoutCtx: logoutHandler,
        resetTimer: resetTimer,
        setAccessTokenCtx: setAccessToken,
        setAuthTokenCtx: setAuthToken,
        setCognitoCtx: setCognito,
        setActiveSiteCtx: setActiveSite,
        setCustomerCodeCtx: setCustomerCode,
        setCustomerTypeCtx: setCustomerType,
    };

    return (<AuthContext.Provider value={contextvalue}>
        {children}
    </AuthContext.Provider>);
}

export default AuthContext;