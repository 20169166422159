import React from 'react'
import { uuid } from '../../hooks/utils'
import style from "./tableLoader.module.css"

type Props = {
    columns: number,
    rows: number
}

const TableLoader: React.FC<Props> = ({columns, rows}) => {
    const cols = [...Array(columns)].map((_, i) => <td key={uuid()}><p className={style.skeleton}>&nbsp;</p></td>);
    const table = [...Array(rows)].map((_, i) => <tr key={uuid()}>{cols}</tr>);
    return (
        <>
            {table}
        </>
    )
}

export {TableLoader}