import * as Constants from '../constants';
import { queryStringGenerator } from "./common.controller";

/* SIGNIN MODEL */

let signInModel = {
    // customer_code: cCode,
    people_id: null,
    first_name: null,
    last_name: null,
    other_names: null,
    person_type: null,
    year_level: null,
    home_level: null,
    visitor_type: null,
    phone_number: null,
    email: null,
    company_name: null,
    badge: null,
    visitor_text: null,
    who_seeing_first_name: null,
    who_seeing_last_name: null,
    who_seeing_other_names: null,
    who_seeing_people_id: null,
    who_seeing_person_type: null,
    who_seeing_email: null,
    visitor_fields: null,
    location: null
};


/*
    USED TO FETCH ATTENDANCE RECORDS FROM DATABASE
    FUNCTION PARAMETERS : filters (OPTIONAL)
    USAGE
        -> {'timein': '2020-01-25'}
        -> {'peopleId': '267a3803-e4f2-4fdc-a088-b062b677cf14'}
        -> {'stillhere': 'true'}
        -> {'persontype': 'visitor'}
    NOTE : IF QUERY STRING PARAMETER NOT PASSED THEN IT WILL RETUN ALL DATA
*/


export async function getVisitLogsV2(filters = {}) {

    /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
    let queryString = await queryStringGenerator(filters, Constants.queryParams.visitor_attendance);

    const headers = Object.assign(Constants.commonHeaders, {});
    // console.log(`${queryString}`) // debugging
    return fetch(`${Constants.baseURL}${Constants.visitorAttendanceAPI}${queryString}`, {
            method: 'GET',
            headers,
            crossDomain: true,
        })
        .then(response => {
            return response;
        })
        .then(response => {
            return response.json();
        })
        .catch(error => {
            return error;
        })
}

// async function getVisitLogsV2Export(filters = {}) {
//     /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
//     let queryString = await queryStringGenerator(filters, Constants.queryParams.visitor_attendance);

//     const headers = Object.assign(Constants.commonHeaders, {});
//     return new Promise((resolve, reject) => {
        // $.ajax({
        //     type: 'GET',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.visitorAttendanceAPI}${queryString}`,
        //     timeout: 29000, // sets timeout to 5000 = 5 seconds
        //     retryCount: 0, // start retry count
        //     retryLimit: 1, //will let you retry a determined number of times
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         if (error.statusText == 'timeout') {
        //             date = moment().add(10, 'seconds')
        //             let id=Math.floor(Math.random() * 100000) + 1
        //             data = {
        //                'todate': moment(date, 'X').utc().format(moment.defaultFormat),
        //                'title' : 'Whoops! The data request exceeded capacity. Try selecting a smaller date range and export again.',
        //                'id': id
        //             }
        //             localStorage.setItem('showerrorMessage',JSON.stringify(data))
        //             showerrorMessage()
        //         }
        //         reject(error);
        //     },
        // });
//     });
// }


// async function exportSignInLogs(filters = {}) {
//     const queryString = await queryStringGenerator(filters, Constants.queryParams.visitor_attendance);

//     const headers = Object.assign(Constants.commonHeaders, {});
//     return new Promise((resolve, reject) => {
        // $.ajax({
        //     type: 'POST',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${exportSignInLogsPath}${queryString}`,
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     }
        // })
//     })
// }


/* USED TO FETCH VISIT LOG BY visitor_attendance_id 
function getVisitLogByIdV2(visitorAttendnaceId) {
    return new Promise((resolve, reject) => {
        let headers = Object.assign(Constants.commonHeaders, {});
        // $.ajax({
        //     type: 'GET',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.visitorAttendanceAPI}/${visitorAttendnaceId}`,
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
    });
}*/


/* USED TO DELETE VISIT LOG BY visitor_attendance_id 
function deleteVisitLogV2(visitorAttendnaceId) {
    return new Promise((resolve, reject) => {
        let headers = Object.assign(Constants.commonHeaders, {});
        // $.ajax({
        //     type: 'DELETE',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.visitorAttendanceAPI}/${visitorAttendnaceId}`,
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
    });
}
*/

/* USED TO SIGN IN USER VISIT 
function signInUserV2(signinRecord) {
    const headers = Object.assign(Constants.commonHeaders, {});

    return new Promise(async (resolve, reject) => {
        signinRecord = { ...signInModel, ...signinRecord };

        /* CONVERT EMAIL TO LOWERCASE */
        // if (signinRecord.email) {
        //     signinRecord.email = signinRecord.email.toLowerCase();
        // }

        // signinRecord.visitor_attendance_id = uuid();

        // $.ajax({
        //     type: 'POST',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.visitorAttendanceAPI}`,
        //     data: JSON.stringify(signinRecord),
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
//     });
// }


// function updateVisitorAttendanceV2(visitor_attendance_id, attendanceRecord) {
//     const headers = Object.assign(Constants.commonHeaders, {});

//     return new Promise(async (resolve, reject) => {
        // $.ajax({
        //     type: 'PATCH',
        //     crossDomain: true,
        //     headers,
        //     url: `${Constants.baseURL}${Constants.visitorAttendanceAPI}/${visitor_attendance_id}`,
        //     data: JSON.stringify(attendanceRecord),
        //     success: (response) => {
        //         resolve(response);
        //     },
        //     error: (error) => {
        //         reject(error);
        //     },
        // });
//     });
// }