import { FC } from "react";
import "./NotificationWidget.css"

const NotificationWidget: FC<{
  notification: string;
  notificationType: string;
  downloadLink: string;
  dispCloseButton: boolean;
  ctaButtonMessage: string;
  closeBtnAction: () => void;
}> = ({ notification, notificationType = "success", downloadLink, dispCloseButton, ctaButtonMessage, closeBtnAction = () => {} }) => {
    let notificationTypeClasses;
    switch (notificationType) {
        case 'success':
            notificationTypeClasses = "bg-light-success";
        break;
        case 'error':
            notificationTypeClasses = "bg-light-danger";
        break;
        case 'warning':
            notificationTypeClasses = "bg-light-warning";
        break;
        case 'info':
            notificationTypeClasses = "bg-light-info";
        break;
        default:
            notificationTypeClasses = "bg-light-success";
        break;
    }
  return (
    <>
    {notification ?
    <div className={`d-flex mt-2 align-items-center card-notifications ${notificationTypeClasses} rounded p-4 mb-7`}>
      {notificationType === 'success' && <i className="bi bi-check-circle-fill"></i>}
      {notificationType !== 'success' && <i className="bi bi-exclamation-circle-fill"></i>}

      <div className="flex-grow-1 me-2 px-3 fw-bolder">
        {notification}
        {downloadLink &&
          <a href={`${downloadLink}`} className="fw-bolder text-primary p-1">
            {ctaButtonMessage}
          </a>
        }
      </div>
      {dispCloseButton && (
        <i className="bi bi-x-square-fill closeButton" onClick={closeBtnAction} ></i>
      )}
    </div>
    : console.error(`Notification message value not found. Pass in your message as a 'notification' prop`)}
    </>
  );
};

export {NotificationWidget};
