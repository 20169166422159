import { FC, useEffect, useState } from "react";
import './AlertWidget.css';

const AlertWidget: FC<{message: string|boolean}> = ({message = "Something went wrong!"}) => {
    const [show, setShow] = useState(true)

    useEffect(() => {
        setShow(true)
        let timeId = setTimeout(() => setShow(false), 3000)
        return () => clearTimeout(timeId)
    }, [message]);

    return (
        <>
            {show && <div className="card-toaster p-2">
                <div className='flex-grow-1 me-2 px-3'>
                    {message}
                </div>
            </div>}
        </>
    )
}

export {AlertWidget};