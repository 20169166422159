/* eslint-disable jsx-a11y/anchor-is-valid */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateWidget.css";

type Props = {
  className: string;
  startDate: Date;
  onChange: any;
};

const DateWidget: React.FC<Props> = ({ className, startDate, onChange }) => {

  return (
    <div className={`card card-dashboard${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
        {/* begin::Hidden */}
        <div className="d-flex flex-stack flex-wrap flex-grow-1 px-7 pt-7 pb-7">
          <div className="me-2">
            <span className="fw-bolder text-gray-800 d-block fs-6">
              Select Date:
            </span>
          </div>

          <div className={`fw-bolder fs-3 selectDate col-md-8`}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              dateFormat="d MMM y"
              showDisabledMonthNavigation
            />
          </div>
        </div>
        {/* end::Hidden */}
      </div>
    </div>
  );
};

export {DateWidget};
