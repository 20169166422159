import {EMAIL_REGEX} from '../constants'
/*exported Toaster, Redirector, showLoader, hideLoader, MsgToaster, validateSchema, capitalize*/

/*Function is used to capitalize first character of string */
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1).trim();
}

export function nameGenerator (first_name = "", last_name = "", people_id = "") {
    first_name = first_name ? capitalize(first_name) : "";
    last_name = last_name ? capitalize(last_name) : '';
    let full_name = `${first_name} ${last_name}`.trim() ? `${first_name} ${last_name}`.trim() : people_id
    return full_name
}

export function uuid() {
    var d = new Date().getTime();
    var uuid = '';
    uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function(c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
}


function validateEmail(email) {
    return EMAIL_REGEX.test(String(email).toLowerCase());
}


// function getLocalStorageData(type){
//     return new Promise((resolve, reject)=> {
//         options = '';
//         switch (type) {
//             case '_staff':
//                 try {
//                     _staff = JSON.parse(localStorage.getItem('_staff'));
//                     _.each(_.sortBy(_staff, 'first_name'), function(user) {
//                         if (user.email && user.email !== 'none' && user.person_type === 'teacher') {
//                             options += `<option value="${user.email}">${nameGenerator(user.first_name, user.last_name, user.people_id)}</option>`;
//                         }
//                     });
//                 } catch (err) {
//                     console.log('Err Unable to fetch visitor types');
//                 }
//                 break
//             default:
//                 options = "";

//         }
//         resolve(options);
//     })
// }