import React, {FC} from 'react'
import {Modal, Button} from 'react-bootstrap-v5'

const ModalWidget: FC<{
    title: string;
    modalBody: any;
    closeModal: () => void;
    showModal: boolean;
    closeBtnText: string;
    confirmBtnText: string;
    size?: "lg" | "sm" | "xl" | undefined;
    confirmBtnAction: () => void;
  }> = ({title, modalBody, closeModal, showModal, closeBtnText="Close", confirmBtnText="Confirm", size, confirmBtnAction}) => {
    return (
        <Modal show={showModal} onHide={closeModal} size={size}>
          <Modal.Header>
              <h1>{title}</h1>
          </Modal.Header>
          <Modal.Body>{modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary cancelButton" onClick={closeModal}>
              {closeBtnText}
            </Button>
            <Button variant="ll" onClick={confirmBtnAction}>
              {confirmBtnText}
            </Button>
          </Modal.Footer>
        </Modal>
    )
}

export {ModalWidget}