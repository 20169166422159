import * as Constants from '../constants';

export function customer_settings() {
//   const [counter, setCounter] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       forwards ? setCounter((prevCounter) => prevCounter + 1) : setCounter((prevCounter) => prevCounter - 1);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [forwards]);

//   return counter;
    // console.log('customer settings');
}

export async function getSiteById(id) { // id == site_id
    return new Promise((resolve, reject) => {
        const headers = { ...Constants.commonHeaders };
        const url = `${Constants.baseURL}${Constants.customerSettingsAPI}/sites/${id}`;

        fetch(url, {
                headers,
            }).then(response => response.json())    // json() is not a function so may not work
            .then(response => {
                resolve(response.returnData);
            }).catch(error => {
                reject(error)
            })
    });
}


