/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import { handleYearLevel } from "../dashboard-hooks";

type Props = {
  className: string;
  users: any[];
  selectedYear: string;
  yearChange: (e: string) => void;
};

const YearLevel: FC<Props> = ({ className, users, selectedYear, yearChange}) => {
    const [yearLevels, setYearLevels] = useState<string[]>([]);
    const [selectedYearLevel, setSelectedYearLevel] = useState(selectedYear);
    
    useEffect(() => {
        handleYearLevel(users, setYearLevels)
    }, [users, selectedYearLevel])

  return (
    <div className={`card card-dashboard${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
        {/* begin::Hidden */}
        <div className="d-flex flex-stack flex-wrap flex-grow-1 px-4 pl-3 pt-7 pb-9">
          <div className="me-2">
            <span className="fw-bolder text-gray-800 d-block fs-6">
              Year Level:
            </span>
          </div>

          <div className={`fw-bolder fs-3 col-md-5`}>
              <select onChange={(e: {target: {value: string}}) => { yearChange(e.target.value); setSelectedYearLevel(e.target.value) }}>
                  <option value={selectedYear}>{selectedYear}</option>
                  {yearLevels.map(year => <option value={year}>{year}</option>)}
              </select>
          </div>
        </div>
        {/* end::Hidden */}
      </div>
    </div>
  );
};

export { YearLevel };
