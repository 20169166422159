import Cookies from "universal-cookie";
const cookies = new Cookies();
/* exported queryStringGenerator, queryStringGenerator, fixBlankToNull, removeCookies, setCookies, capitalize, sortCaseInsensitive */

export function queryStringGenerator(filterObj, paramsArr) {
    /*
        fileterObj : "object which contains querystring params with values"
        paramsArr : "Default querystring paramers array"
    */
    return new Promise(((resolve) => {
        if (filterObj && typeof(filterObj) === 'object' && Object.keys(filterObj).length > 0) {
            const queryStringArr = [];
            /*
                LOOP THROUGH QUERY STRING PARAMETERS ARRAY AND GENERATE QUERYSTRING
                IF PARAMETER EXIST IN FILETER OBJECT AND PARAMETER HAS VALUE
            */
            Object.keys(filterObj).map((key, index) => {
                queryStringArr.push(`${key}=${filterObj[key]}`);
                if ((index + 1) == Object.keys(filterObj).length) {
                    resolve(`?${queryStringArr.join('&')}`);
                }
            })
        } else {
            resolve('');
        }
    }));
}

/* GET QUERYSTRING PARAMETER VALUE 
function getQueryStringValue(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1',
        ),
    );
}
*/
/*FIX BLANK TO NULL OR TYPE OF VARIABLE IN SCHEMA*/
export function fixBlankToNull(modelSchema, obj) {
    return new Promise((resolve, reject) => {
        Object.keys(obj).forEach((key) => {
            if (!obj[key]) {
                if (modelSchema && modelSchema.hasOwnProperty(key)) {
                    obj[key] = modelSchema[key];
                } else {
                    obj[key] = null;
                }
            }
        })
        resolve(obj)
    })
}


/*FUNCTION IS USED TO REMOVE COOKIES*/
// function removeCookies() {
//     var cookies = cookies.get();
//     for (var cookie in cookies) {
//         $.removeCookie(cookie);
//     }
// }

/*FUNCTION IS USED TO SET COOKIES with Array Values */
export function setCookies(obj) {
    Object.keys(obj).forEach(function(key) {
        cookies.set(key, obj[key].join(","), { path: '/' });
    })
}

/*
    CAPITALIZE STIRNG
    INPUT: john doe
    OUTPUT: John doe

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}*/


/* FUNCTION IS USED TO SORT DATA CASE INSENSITIVELY 
function sortCaseInSensitive(array, fieldName) {
    // return new Promise((resolve, reject) => {
    //     var a = _.sortBy(arr, function(x) {
    //         return x[fieldName].toLowerCase();
    //     });
    //     resolve(a);
    // })
}
*/
/* get values from dropdown rooms, students by site or all site 
function get_drop_down_data(drop_down_type, site = 'ALL', allowAll=true) {
    // var returnData = "";
    // var sites = JSON.parse(localStorage.getItem('_sites'));

    // // FILTER FOR A SINGLE SITE
    // if (site !== 'ALL') {
    //     sites = _.where(sites, { site_id: site });
    // }

    // switch (drop_down_type) {
    //     case 'rooms':
    //         returnData += allowAll ? '<option value="%ALL"> All</option>' : '<option value="" disabled selected>---- Select Location ----</option>';
    //         sites.forEach((single_site) => {
    //             single_site.rooms && single_site.rooms.forEach((room) => {
    //                 returnData += `<option value="${room}">${room} (${single_site.site_name})</option>`;
    //                 });
    //         })
    //         break;
    //     case 'sites':
    //         returnData = '<option value="%ALL"> All</option>';
    //         if (sites && sites.length > 0) {
    //             for (var site of sites) {
    //                 returnData += `<option value="${site.site_id}">${site.site_name}</option>`;
    //             }
    //         }
    //     case 'site_time_zone':
    //         break;
    //     default:
    //         break;
    // }
    // return returnData;
}

// UPDATE ROOMS DROPDOWN
function updateRoomSegment(element,site, allowAll=true){
    // $(element).html(get_drop_down_data('rooms',site, allowAll));
    // $(element).select2('val', '%ALL');
}*/