import * as Constants from "../constants";
import { queryStringGenerator } from "./common.controller";

/* SCHEDULE VISIT MODEL */

const scheduleVisitModel = {
  visitor_schedule_id: null,
  // customer_code: cCode,
  first_name: null,
  last_name: null,
  email: null,
  other_names: null,
  person_type: null,
  phone_number: null,
  people_id: null,
  host_first_name: null,
  host_last_name: null,
  host_email: null,
  host_person_type: null,
  host_people_id: null,
  expected_time_in: null,
  expected_time_out: null,
  location: null,
};

/*
    USED TO FETCH SCHEDULED VISITS FROM DATABASE
    FUNCTION PARAMETERS : filters (OPTIONAL)
    USAGE
        -> {'timein': '2020-01-25'}
        -> {'email': 'user@looplearn.net'}
    NOTE : IF QUERY STRING PARAMETER NOT PASSED THEN IT WILL RETUN ALL DATA
*/
export async function getScheduledVisitsV2(filters = {}) {
  /*GENERATE QUERY STRING PARAMETER ACCORDING TO FILTER*/
  let queryString = await queryStringGenerator(
    filters,
    Constants.queryParams.visitor_schedule
  );
  const headers = Object.assign(Constants.commonHeaders, {});
  return fetch(
    `${Constants.baseURL}${Constants.visitorScheduleAPI}${queryString}`,
    {
      method: "GET",
      headers,
      crossDomain: true,
    }
  )
    .then((response) => {
      return response;
    })
    .then((response) => response.json())
    .catch((error) => {
      return error;
    });
}

/* USED TO FETCH SCHEDULED VISIT BY visitor_schedule_id */
// function getScheduledVisitByIdV2(visitorScheduleId) {
//   return new Promise((resolve, reject) => {
//     var headers = Object.assign(Constants.commonHeaders, {});
    // $.ajax({
    //   type: "GET",
    //   crossDomain: true,
    //   headers,
    //   url: `${Constants.baseURL}${Constants.visitorScheduleAPI}/${Constants.visitorScheduleId}`,
    //   success: (response) => {
    //     resolve(response);
    //   },
    //   error: (error) => {
    //     reject(error);
    //   },
    // });
//   });
// }

/* USED TO DELETE SCHEDULED VISIT BY visitor_schedule_id */
// function deleteScheduledVisitV2(visitorScheduleId) {
//   return new Promise((resolve, reject) => {
//     var headers = Object.assign(Constants.commonHeaders, {});
    // $.ajax({
    //   type: "DELETE",
    //   crossDomain: true,
    //   headers,
    //   url: `${Constants.baseURL}${Constants.visitorScheduleAPI}/${Constants.visitorScheduleId}`,
    //   success: (response) => {
    //     resolve(response);
    //   },
    //   error: (error) => {
    //     reject(error);
    //   },
    // });
//   });
// }

/* USED TO SCHEDULE VISIT */
// function scheduleVisitV2(scheduleVisitRecord) {
//   const headers = Object.assign(Constants.commonHeaders, {});

//   return new Promise(async (resolve, reject) => {
//     scheduleVisitRecord = { ...scheduleVisitModel, ...scheduleVisitRecord };

//     /* CONVERT EMAIL TO LOWERCASE */
//     if (scheduleVisitRecord.email) {
//       scheduleVisitRecord.email = scheduleVisitRecord.email.toLowerCase();
//     }

//     scheduleVisitRecord.visitor_schedule_id = uuid();

    // $.ajax({
    //   type: "POST",
    //   crossDomain: true,
    //   headers,
    //   url: `${Constants.baseURL}${Constants.visitorScheduleAPI}`,
    //   data: JSON.stringify(scheduleVisitRecord),
    //   success: (response) => {
    //     resolve(response);
    //   },
    //   error: (error) => {
    //     reject(error);
    //   },
    // });
//   });
// }
