import {FC, useState, useEffect} from 'react';
import { getStaffCount } from '../dashboard-hooks';

type Props = {
    signInLogs: any[],
    showLogs: () => void
}

const StaffOnSite: FC<Props> = ({signInLogs = [], showLogs}) => {
    const [staffCount, setStaffCount] = useState(0);
    
    useEffect(() => {
        getStaffCount(signInLogs, setStaffCount)
    }, [signInLogs])

    return (
    <div className="col-xxl-3" onClick={showLogs}>
        <div className="card card-dashboard card-stretch staffOnSite">
            {/* begin::Body */}
            <div className='card-body py-9 text-center'>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-muted py-3">Staff on Site:</h2>
                        <h1>{staffCount}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )

}

export {StaffOnSite};