import React, { useState, useContext } from 'react';
import ConstantsContext from './Constants-context';

const CustomerDataContext = React.createContext({
    license: {},
    customer_type: 'agedcare',
    member_name: 'MEMBER',
    menu_config: [],
    sites: [],
    customer_data: [],
    status: false,
    setSiteCtx: (sites: any) => {},
    setMenuConfigCtx: (menu_config: any) => {},
    setMemberNameCtx: (member_name: any) => {},
    setCustomerTypeCtx: (customer_type: any) => {},
    setLicenseCtx: (license: any) => {},
    setCustomerDataCtx: (customer_data: any) => {},
    setApiCallStatusCtx: (status: any) => {},
});

export const CustomerDataContextProvider: React.FC = ({children}) => {
    const [CustomerData, setCustomerData] = useState([]);
    const constantsCtx = useContext(ConstantsContext);
    const [License, setLicense] = useState(constantsCtx.LICENSES['STANDALONE']);
    const [CustomerType, setCustomerType] = useState('');
    const [MemberName, setMemberName] = useState('');
    const [MenuConfig, setMenuConfig] = useState([]);
    const [Sites, setSites] = useState([]);
    const [apiCallStatus, setApiCallStatus] = useState(false)

    const contextvalue = {
        customer_data: CustomerData,
        license: License,
        customer_type: CustomerType,
        member_name: MemberName,
        menu_config: MenuConfig,
        sites: Sites,
        status: apiCallStatus,
        setSiteCtx: setSites,
        setMenuConfigCtx: setMenuConfig,
        setMemberNameCtx: setMemberName,
        setCustomerTypeCtx: setCustomerType,
        setLicenseCtx: setLicense,
        setCustomerDataCtx: setCustomerData,
        setApiCallStatusCtx: setApiCallStatus
    };

    return (<CustomerDataContext.Provider value={contextvalue}>
        {children}
    </CustomerDataContext.Provider>);
}

export default CustomerDataContext;