/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Registration } from "./components/Registration";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import ConstantsContext from "../../../store/Constants-context";

export function Redirector() {
  const constantsCtx = useContext(ConstantsContext);
  useEffect(() => {
    window.location.href =
      "https://" + constantsCtx.URL_ENV + ".looplearn.live/oauth.html";
  }, []);

  return <></>;
}

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="bgi-attachment-fixed bgi-no-repeat bgi-position-x-center bgi-position-y-top d-flex flex-column flex-column-fluid position-x-center"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/misc/Homepage-2-1.png"
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Switch>
            <Route path="/auth/registration/:token" component={Registration} />
            <Route path="/" component={Redirector} />
            {/* <Route path='/auth/login' component={Login} />
            <Route path='/auth/forgot-password' component={ForgotPassword} /> */}
            {/* <Redirect from='/auth' exact={true} to='/auth/login' /> */}
            {/* <Redirect to='/auth/login' /> */}
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://looplearn.net"
            className="text-white text-hover-primary px-2"
          >
            Home
          </a>

          <a
            href="https://looplearn.net/about-us"
            className="text-white text-hover-primary px-2"
          >
            About
          </a>

          <a
            href="https://looplearn.net/contact-us"
            className="text-white text-hover-primary px-2"
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
}
