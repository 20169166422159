import {FC, useState, useEffect } from 'react'
import { OverdueVisitorsOnSiteRecords, OverdueVisitorsOnSite_Records } from '../dashboard-hooks';

const OverdueVisitorsOnSite: FC<{signInLogs: any}> = ({signInLogs = []}) => {

    const [visitorCount, setVisitorCount] = useState(0);
    const [visitorRecords, setVisitorRecords] = useState<OverdueVisitorsOnSite_Records[]>([]);
    useEffect(() => {
        OverdueVisitorsOnSiteRecords(signInLogs, setVisitorCount, setVisitorRecords);
    }, [signInLogs])

return (
    <div className="col-xl-8 overdueSiteVisitors">
        <div className="card card-dashboard card-stretch">
        {/* begin::Body */}
        <div className='card-body py-9'>
            <div className="row">
            <div className="col-md-12">
                <h2 className="text-muted py-3">Overdue Visitors on Site:</h2>
            </div>
            <div className="col-md-4  align-items-center d-flex flex-grow-1 justify-content-center m-b-0 m-t-0 ">
                <h1>{visitorCount}</h1>
            </div>
            <div className="col-md-8 text-center table-responsive">
                <table className="no-footer table align-middle gs-0 gy-5" id="tbl_overdue_visitors">
                        {visitorCount === 0 && <tbody id="tbody_overdue_visitors text-center"><tr><td className="noRecords">No records available...</td></tr></tbody> }
                        {visitorCount > 0 &&  <thead><tr style={{border:"none"}}><th><b>Name</b></th><th><b>Host</b></th><th><b>Arrival</b></th><th><b>Expected Out</b></th><th></th></tr></thead>}
                        {visitorCount > 0 &&  
                            <tbody>
                            {visitorRecords.map((rec: OverdueVisitorsOnSite_Records) => 
                                <tr key={rec.visitor_attendance_id} style={{borderBottom: "2px solid #000"}}>
                                    <td>{rec.full_name}</td>
                                    <td>{rec.host}</td>
                                    <td>{rec.arrival}</td>
                                    <td>{rec.expected_time_out}</td>
                                    <td>
                                        <a style={{cursor:"pointer"}} href={`visit_details.html?type=${rec.person_type}&visitor_attendance_id=${rec.visitor_attendance_id}`}>
                                            <i className="bi bi-chevron-right fs-1"></i>
                                        </a>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        }
                </table>
            </div>
            </div>
        </div>
        </div>
    </div>
    )
}


export {OverdueVisitorsOnSite};
