/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { register } from "../redux/AuthCRUD";
import { Error404 } from "../../errors/components/Error404";
import Alert1 from "../../../components/Alert1";
import ErrorMessage1 from "../../../components/ErrorMessage1";
import ConstantsContext from "../../../../store/Constants-context";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  organisationName: "",
  phoneNumber: "",
  mobileNumber: "",
  country: "",
  streetAddress: "",
  city: "",
  state: "",
  postalCode: "",
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters required.")
    .required("First name is required"),
  lastname: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(1, "Minimum 1 character required.")
    .max(50, "Maximum 50 characters required.")
    .required("Last name is required"),
  email: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .email("Wrong email format")
    .min(3, "Minimum 3 characters required.")
    .max(50, "Maximum 50 characters required.")
    .required("Email is required"),
  organisationName: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(2, "Minimum 2 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("Organisation Name is required"),
  phoneNumber: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(8, "Minimum 8 characters required.")
    .max(13, "Maximum 12 characters required.")
    .required("Phone Number is required"),
  mobileNumber: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(8, "Minimum 8 characters required.")
    .max(13, "Maximum 12 characters required.")
    .required("Mobile Number is required"),
  country: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(3, "Minimum 3 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("Country name is required"),
  streetAddress: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(3, "Minimum 3 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("Street Address is required"),
  city: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(3, "Minimum 3 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("City name is required"),
  state: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(3, "Minimum 3 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("State name is required"),
  postalCode: Yup.string()
    .trim("Cannot include leading and trailing spaces")
    .min(3, "Minimum 3 characters required.")
    .max(200, "Maximum 50 characters required.")
    .required("Postal code is required"),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
});

const decodeToken = (encodedToken: string): string => {
  return atob(encodedToken);
};

export function Registration(props: any) {
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerMessageTitle, setRegisterMessageTitle] = useState("");
  const [registerMessageDescription, setRegisterMessageDescription] =
    useState("");
  const constantsCtx = useContext(ConstantsContext);

  useEffect(() => {
    try {
      setAuthToken(decodeToken(props.match.params.token));
    } catch (e) {
      if (e instanceof DOMException) {
        console.error("[DOMException]", e.message);
      } else {
        console.error("[Error]", e);
      }
    }
  }, [props.match.params.token]);

  // const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log("submitting", authToken);
      setLoading(true);
      setIsSubmitted(true);
      register(
        values.email,
        values.firstname,
        values.lastname,
        values.organisationName,
        values.phoneNumber,
        values.mobileNumber,
        values.country,
        values.streetAddress,
        values.city,
        values.state,
        values.postalCode,
        values.acceptTerms,
        authToken
      )
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((data) => {
          if (
            data.hasOwnProperty("userMessage") &&
            data.userMessage === "Success"
          ) {
            setRegisterSuccess(true);
            setRegisterMessageTitle("Success");
            setRegisterMessageDescription(
              "You will now receive an email with temporary login credentials."
            );
          } else if (
            data.hasOwnProperty("message") &&
            data.message === "Unauthorized"
          ) {
            setRegisterSuccess(false);
            setRegisterMessageTitle("Authentication failure");
            setRegisterMessageDescription(
              "Your session has expired. Please restart the device and register with the new QR code."
            );
          } else {
            setRegisterSuccess(false);
            setRegisterMessageTitle("Something went wrong");
            setRegisterMessageDescription(
              "Please check if all the entered values are valid."
            );
          }
        })
        .catch((e) => {
          console.error("Error", e);
          setLoading(false);
          setSubmitting(false);
          // setStatus('Registration process has broken')

          setRegisterMessageDescription(
            "Something went wrong. Please check if all the entered values are valid."
          );
          // "Something is not right. Please check if all the entered values are valid."
        });
    },
  });

  if (authToken) {
    return (
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Logo */}
        <div className="text-center">
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/loopsafe_logo.png")}
              className="h-45px"
            />
          </a>
        </div>
        {/* end::Logo */}
        {/* begin::Heading */}
        <div className="my-10 text-center">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Create an Account</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Already have an account?
            <a
              href={"https://" + constantsCtx.URL_ENV + ".looplearn.live/auth"}
              className="link-primary fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Log In
            </a>
          </div>
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        <div className="d-flex align-items-center mb-10">
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
          {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        </div>

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname & Lastname */}
        <div className="row fv-row mb-7">
          <div className="col-xl-6">
            <div className="fv-row mb-5">
              <label className="form-label fw-bolder text-dark fs-6">
                First name
              </label>
              <input
                placeholder="First name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("firstname")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    "is-valid":
                      formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <ErrorMessage1
                  color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                  errorMessage={formik.errors.firstname}
                />
              )}
            </div>
          </div>
          <div className="col-xl-6">
            {/* begin::Form group Lastname */}
            <div className="fv-row mb-5">
              <label className="form-label fw-bolder text-dark fs-6">
                Last name
              </label>
              <input
                placeholder="Last name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastname")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    "is-valid":
                      formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <ErrorMessage1
                  color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                  errorMessage={formik.errors.lastname}
                />
              )}
            </div>
            {/* end::Form group */}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className="fv-row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">Email</label>
          <input
            placeholder="Email"
            type="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.email}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Organisation Name */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Organisation Name
          </label>
          <input
            placeholder="Organisation Name"
            type="text"
            {...formik.getFieldProps("organisationName")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.organisationName &&
                  formik.errors.organisationName,
              },
              {
                "is-valid":
                  formik.touched.organisationName &&
                  !formik.errors.organisationName,
              }
            )}
          />
          {formik.touched.organisationName &&
            formik.errors.organisationName && (
              <ErrorMessage1
                color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                errorMessage={formik.errors.organisationName}
              />
            )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Phone Number */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Phone Number
          </label>
          <input
            placeholder="Phone Number"
            type="tel"
            {...formik.getFieldProps("phoneNumber")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.phoneNumber && formik.errors.phoneNumber,
              },
              {
                "is-valid":
                  formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.phoneNumber}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Mobile Number */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Mobile Number
          </label>
          <input
            placeholder="Mobile Number"
            type="text"
            {...formik.getFieldProps("mobileNumber")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.mobileNumber && formik.errors.mobileNumber,
              },
              {
                "is-valid":
                  formik.touched.mobileNumber && !formik.errors.mobileNumber,
              }
            )}
          />
          {formik.touched.mobileNumber && formik.errors.mobileNumber && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.mobileNumber}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Country */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">Country</label>
          <input
            placeholder="Country"
            type="text"
            {...formik.getFieldProps("country")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.country && formik.errors.country },
              {
                "is-valid": formik.touched.country && !formik.errors.country,
              }
            )}
          />
          {formik.touched.country && formik.errors.country && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.country}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Street Address */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Street Address
          </label>
          <input
            placeholder="Street Address"
            type="text"
            {...formik.getFieldProps("streetAddress")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.streetAddress && formik.errors.streetAddress,
              },
              {
                "is-valid":
                  formik.touched.streetAddress && !formik.errors.streetAddress,
              }
            )}
          />
          {formik.touched.streetAddress && formik.errors.streetAddress && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.streetAddress}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group City */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">City</label>
          <input
            placeholder="City"
            type="text"
            {...formik.getFieldProps("city")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.city && formik.errors.city },
              {
                "is-valid": formik.touched.city && !formik.errors.city,
              }
            )}
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.city}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group State */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">State</label>
          <input
            placeholder="State"
            type="text"
            {...formik.getFieldProps("state")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.state && formik.errors.state },
              {
                "is-valid": formik.touched.state && !formik.errors.state,
              }
            )}
          />
          {formik.touched.state && formik.errors.state && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.state}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Postal Code */}
        <div className="fv=row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Postal Code
          </label>
          <input
            placeholder="Postal Code"
            type="text"
            {...formik.getFieldProps("postalCode")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.postalCode && formik.errors.postalCode,
              },
              {
                "is-valid":
                  formik.touched.postalCode && !formik.errors.postalCode,
              }
            )}
          />
          {formik.touched.postalCode && formik.errors.postalCode && (
            <ErrorMessage1
              color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
              errorMessage={formik.errors.postalCode}
            />
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <label
              className="form-check-label fw-bold text-gray-700 fs-6"
              htmlFor="kt_login_toc_agree"
            >
              I Agree the{" "}
              <a
                href="https://a3c0acac-84ef-41ed-8dd7-b8fa706aa47d.filesusr.com/ugd/75e0d9_757351e903bf4edb978677803403e1ec.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1 link-primary"
              >
                terms and conditions
              </a>
              .
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <ErrorMessage1
                color={constantsCtx.ERROR_MESSAGE_COLOR_RED}
                errorMessage={formik.errors.acceptTerms}
              />
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Success Alert */}
        {registerSuccess && !loading && isSubmitted && (
          <Alert1
            alertTitle="Success"
            alertMessage="You will now receive an email with temporary login credentials."
            success={true}
          />
        )}
        {/* end::Success Alert */}

        {/* begin::Failure Alert */}
        {!registerSuccess && !loading && isSubmitted && (
          <Alert1
            alertTitle={registerMessageTitle}
            alertMessage={
              registerMessageDescription ? registerMessageDescription : ""
            }
            success={false}
          />
        )}
        {/* end::Failure Alert */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms ||
              registerSuccess ||
              (!registerSuccess && Boolean(registerMessageTitle))
            }
          >
            {registerSuccess && (
              <span className="indicator-label">Finished</span>
            )}
            {!registerSuccess && !loading && (
              <span className="indicator-label">Submit</span>
            )}
            {!registerSuccess && loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          {/* <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link> */}
        </div>
        {/* end::Form group */}
      </form>
    );
  } else {
    return <Error404 />;
  }
}
