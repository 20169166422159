import {FC, useState, useEffect} from 'react';
import { getVisitorCount } from "../dashboard-hooks";

const VisitorsOnSite: FC<{signInLogs: any[], showLogs: () => void}> = ({signInLogs = [], showLogs}) => {
    const [visitorCount, setVisitorCount] = useState(0);
    const [dependentsCount, setDependentsCount] = useState(0);

    useEffect(() => {
      getVisitorCount(signInLogs, setVisitorCount, setDependentsCount);
    }, [signInLogs]);
    
    return (
    <div className="col-xxl-3">
        <div className="card card-dashboard card-stretch visitorsOnSite" onClick={showLogs}>
          {/* begin::Body */}
          <div className='card-body py-9 text-center'>
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-muted py-3">Visitors on Site:</h2>
                <h1>{visitorCount}</h1>
                <h2 className="text-muted py-3">Dependents: {dependentsCount}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export {VisitorsOnSite};