import * as Constants from '../constants';
import { getSiteById } from "./customer_settings"
import { getPeopleV2 } from "./people.controller"
import { nameGenerator } from "./utils"
import { queryStringGenerator, fixBlankToNull } from "./common.controller";
import moment from 'moment-timezone';

import Cookies from "universal-cookie";
const cookies = new Cookies();


export const endSession = () => {   //   same as logout() in common.js jquery project
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    localStorage.clear();
    cookies.remove('authToken')
    window.location.href = Constants.logoutUrl
}

export function reloadSitesDropdown() {
    (!cookies.get("authToken") || cookies.get("authToken") === "undefined") && window.location.replace("/") // if authToken is not set, redirect to login screen in the jquery app
    let sitesData = JSON.parse(localStorage.getItem("_sites"));
    let activeSite = cookies.get("activeSite");
    _loadSiteDataGlobal(activeSite);
    return sitesData;
}

export async function _loadSiteDataGlobal(site_id) {
    await Promise.all([
        getSiteById(site_id)
        .then((response) => {
            const siteData = response;
            if (Object.keys(siteData).length) {
                // SET ROOMS IN LOCAL STORAGE
                if (siteData.rooms) {
                setupLocalStorage("_rooms", siteData.rooms);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        }),
        getPeopleV2({
            site_id: cookies.get("activeSite") ? cookies.get("activeSite") : "",
            person_type__in: "teacher,visitor,student,guardian",
            is_delete: "False",
            return_only:
            "person_type,people_id,first_name,last_name,other_names,year_level,home_level,visitor_type,phone_number,email,company_name,badge,employment_type",
        })
        .then((users) => {
            let staff = [];
            let visitors = [];
            let students = [];
            let guardians = [];
            Array.isArray(users) &&
                users.map((user) => {
                    (user.person_type === "teacher") && staff.push(user);
                    (user.person_type === "visitor") && visitors.push(user);
                    (user.person_type === "student") && students.push(user);
                    (user.person_type === "guardian") && guardians.push(user);
                })
            setupLocalStorage("_staff", staff);
            setupLocalStorage("_visitors", visitors);
            setupLocalStorage("_students", students);
            setupLocalStorage("_guardians", guardians);
        })
        .catch((error) => {
            console.log(error);
        }),
        // updateUserV2(
        //     cookies.get("people_id"),
        //     { preferences: { site: site_id } },
        //     "PATCH",
        //     {
        //     site_id: cookies.get("master_site_id"),
        //     person_type: cookies.get("loginRole"),
        //     }
        // )
        updateUserLoginDetail(cookies.get("user_login_id"), {
            preferences: { site: site_id }
            }, "PATCH",
            { Authorization: Constants.AUTH_TOKEN }
        )
        .then((response) => {})
        .catch((error) => {
            console.log(error);
        }),
    ]);

    // UPDATE TIMEZONE IN COOKIE
    updateTimezoneInCookie();
    // if (window.location.pathname !== "/index.html") {
    //     hideLoader();
    // }
}

// FUNCTION WILL SET LOCAL STORAGE DATA
export function setupLocalStorage(field, data) {

    let _data = [];
    switch (field) {
        case "_students":
        case "_visitors":
        case "_staff":
            data.forEach((user) => {
                _data.push({
                    name: nameGenerator(user.first_name, user.last_name, user.people_id),
                    id: user.people_id,
                });
            });
        break;
        case "_rooms":
            _data = data;
        break;
        case "_sites":
            data.forEach((site) => {
                _data.push({
                    site_name: site.site_name,
                    site_id: site.site_id,
                });
            });
        break;
        default:
        break;
    }
    localStorage.setItem(field, JSON.stringify(_data));
    updateGlobalSearchData();
}

export function updateGlobalSearchData() {
    let globalSearchData = [];
    let visitors = JSON.parse(localStorage.getItem("_visitors"));
    
    // CHECK USER MENU ACCESS RIGHTS
    let user_menu_config = cookies.get("user_menu_config") ? cookies.get("user_menu_config").split(",") : [];
    let has_setup_access = user_menu_config.includes("setup") ? true : false;

    // POPULATE SEARCH DATA WITH STAFF ONLY IF USER HAS 'setup' ACCESS RIGHT
    if (has_setup_access) {
        const staff = JSON.parse(localStorage.getItem("_staff"));
        staff && staff.forEach((i) => {
            globalSearchData.push({
                id: i.id,
                text: `${i.name} (Staff)`,
            });
        });
    }

    // POPULATE SEARCH DATA WITH STUDENTS
    const students = JSON.parse(localStorage.getItem("_students"));
    students && students.forEach((student) => {
        globalSearchData.push({
            id: student.id,
            text: `${student.name} (${Constants.MEMBER_NAME})`,
        });
    });

    // POPULATE SEARCH DATA WITH VISITORS
    visitors && visitors.forEach((visitor) => {
        globalSearchData.push({
            id: visitor.id,
            text: `${visitor.name} (Visitor)`,
        });
    });
    localStorage.setItem("globalSearchData", JSON.stringify(globalSearchData));
    
    // const $globalSearch = globalSearchReset();
    // setTimeout(function () {
    //     $globalSearch.autocomplete2({
    //         data: globalSearchData,
    //     });
    //     let $autocomplete = jQuery("#globalSearch").siblings(
    //         ".autocomplete-content"
    //     );

    //     $autocomplete.on("click", "li", function () {
    //         let selecteValue = $(this)[0].innerText;

    //         /*IF SELECTED TEXT CONTAINS Student*/
    //         if (
    //         selecteValue.includes("Student") ||
    //         selecteValue.includes(MEMBER_NAME)
    //         ) {
    //         window.location.href = `edit_user.html?type=student&id=${$(this).data(
    //             "id"
    //         )}`;
    //         }

    //         /*IF SELECTED TEXT CONTAINS Visitor*/
    //         if (selecteValue.includes("Visitor")) {
    //         window.location.href = `edit_user.html?type=visitor&id=${$(this).data(
    //             "id"
    //         )}`;
    //         }

    //         /*IF SELECTED TEXT CONTAINS Visitor*/
    //         if (selecteValue.includes("Staff")) {
    //         window.location.href = `edit_user.html?type=staff&id=${$(this).data(
    //             "id"
    //         )}`;
    //         }
    //         $autocomplete.empty();
    //     });
    // }, 1000);
}

/*
    FUNCTION TO UPDATE TIMEZONE IN COOKIE
*/
export function updateTimezoneInCookie() {
    try {
        let tz = 'Australia/Sydney'; // Default to Sydney
        JSON.parse(localStorage.getItem("_sites")).filter((site) => {
            return site.site_id == cookies.get('activeSite');
        }).forEach((filterSite) => {
            tz = filterSite.timezone;
        });
        cookies.set('timezone', tz);
        const gmt = moment.tz(tz).format('Z');
        cookies.set('gmtAdjustment', gmt);
    }
    catch (e) {
        console.log('updateTimezoneInCookie error: ', e);
        cookies.set('gmtAdjustment', '+10:30');
    }
}

/* GET UNIDENTIFIED PEOPLE LIST */
export function getUnidentifiedPeople(headers) {
    return fetch(`${Constants.apinewurl}/getunidentifiedpeople`, {
        method: 'GET',
        headers,
        crossDomain: true,
    })
    .then(response => {
        return response;
    })
    .then(response => response.json())
    .catch(error => {
        return { returnData: [] };
    })
}

/* GET ALL CUSTOMERDETAILS */
export const getCustomerData = () => {
    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'Content-Type': 'application/json; charset=utf-8',
    };
    /*
    This function is going to be called in almost all pages, so it would make sense to add the sessionexpiry function call here
    */
    // sessionExpiration(idleMinutes = 30, warningMinutes = 28, logoutUrl, serverRefresh = false);
    // sessionExpiration(1, 0.5, Constants.logoutUrl, false);
    return fetch(`${Constants.apinewurl}/getCustomerSettings`, {
        method: 'GET',
        headers: customHeaders,
        crossDomain: true,
        dataType: 'json',
    })
}

/* GET STUDENTS IDENTIFIED DATA (GET ATTENDED PERIODS, LAST SEEN ROOM) */
export async function getStudentIdentifiedData(id, start_date, end_date = '') {
    // return new Promise(function (resolve, reject) {
    if (!end_date) {
        end_date = start_date;
    }
    let customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'Content-Type': 'application/json; charset=utf-8',
        start_date: `${start_date}T00:00:00${Constants.cTimeZoneOffset}`,
        end_date: `${end_date}T23:59:00${Constants.cTimeZoneOffset}`,
    };
    id = id.split(',');
    let reqData = { "studentIds" : id };

    return fetch(`${Constants.apinewurl}/getStudentIdentifiedData`, {
        method: 'POST',
        headers: customHeaders,
        crossDomain: true,
        dataType: 'json',
        body: JSON.stringify(reqData)
    }).then(res => res.json())
    .then(data => {
        return {...data};
    })                
    .catch(error => {
        console.error(error)
    });
    // });
}
/* ENDS */

/* GET CUSTOMER VISITORS ATTENDANCE LIST */
export function getAttendanceLogs(requestData) {
    let customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'respond': 'application/json',
        'Content-Type': 'application/json',
    };
    let reqData = {
        'parameters': {
            start_date: requestData.fromDate,
            end_date: requestData.toDate,
        }
    };
    return fetch(`${Constants.apinewurl}/getAttendanceLogs`, {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify(reqData)
    })
}


/* THIS FUNCTION IS USED TO MANAGE COGNITO USER*/
export async function manageCognitoUser(requestData) {

    const customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'respond': 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(`${Constants.apinewurl}/manageCognitoUser`, {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify({
            'parameters': requestData,
        })
    }).then(response => {
        return response;
    });
}

/*FUNCTION IS USED TO ENABLE/ DISABLE/ VERIFY SOFTWARE TOKEN*/
export async function manageCognitoMFA(headers, requestData) {
    const customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'respond': 'application/json',
        'Content-Type': 'application/json',
    };
    headers = { ...headers, ...customHeaders };
    return fetch(`${Constants.apinewurl}/manageCognitoMFA`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            'parameters': requestData,
        })
    });
}

/* GET UNIDENTIFIED PEOPLE LIST */
export async function getUsers(filters={}) {
    let queryString = await queryStringGenerator(filters, Constants.queryParams.users);
    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'Content-Type': 'application/json; charset=utf-8',
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}${queryString}`, {
        method: 'GET',
        headers: customHeaders,
        crossDomain: true
    })
    .then(response => {
        return response;
    })
    .then(response => response.json())
    .catch(error => {
        return { returnData: [] };
    })
}

/* GET USER BY ID */
export async function getUserById(userLoginId) {
    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,
        'Content-Type': 'application/json; charset=utf-8'
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}/${userLoginId}`, {
        method: 'GET',
        headers: customHeaders,
        crossDomain: true
    })
    .then(response => response.json())
    .catch(error => {
        return { returnData: [] };
    })
}



/* POST method to save user login data in db*/
export async function saveUserLoginDetail(requestData) {

    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,        
        'Content-Type': 'application/json; charset=utf-8',
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}`, {
        method: 'PUT',
        headers: customHeaders,
        body: JSON.stringify({
            'parameters': requestData,
        })
    })
}

/* PUT method to save user login data in db*/
export async function updateUserLoginDetail(userLoginId, requestData) {

    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,        
        'Content-Type': 'application/json; charset=utf-8',
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}/${userLoginId}`, {
        method: 'PATCH',
        headers: customHeaders,
        body: JSON.stringify(requestData)
    });
}

/* DELETE method to delete user login data in db*/
export async function deleteUserLoginDetail(user_login_id) {

    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,        
        'Content-Type': 'application/json; charset=utf-8',
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}`, {
        method: 'DELETE',
        headers: customHeaders,
        body: JSON.stringify({
            'user_login_id': user_login_id
        })
    })
}

/* BULK DELETE method to delete user login data in db*/
export async function bulkDeleteUserLoginDetail(user_login_ids) {

    var customHeaders = {
        'Authorization': Constants.AUTH_TOKEN,        
        'Content-Type': 'application/json; charset=utf-8',
    };
    return fetch(`${Constants.baseURL}${Constants.userLoginAPI}`, {
        method: 'DELETE',
        headers: customHeaders,
        body: JSON.stringify({
            'user_login_ids': user_login_ids
        })
    })
}
