import { bulkDeleteUserLoginDetail, updateUserLoginDetail, manageCognitoUser, uuid, saveUserLoginDetail, getUserById } from "./userManagement-hooks";

export type users = {
  checked: boolean;
  id: string;
  first_name: string;
  last_name: string;
  site_admin: boolean;
  email: string;
  site_access: string[];
  menu_config: string[];
  user_login_id: string;
  search_filters: string;
  username: string;
};

/* USER LIST COMPONENT HOOKS */

export const singleDeleteHandler = async (
    usersList: users[],
    delUserID: string,
    setDispAlertMessage: any,
    setUsersList: any,
    setDelModal: any,
    setSearch: any
) => {
    const user_to_be_deleted: any = [
        usersList.find(element => element.user_login_id === delUserID)
    ];

    if (user_to_be_deleted) {
        const response: any = await bulkDeleteUserLoginDetail([delUserID]);
        if (response.status === 200) {
            setDispAlertMessage("User deleted successfully.");
            setInterval(() => {
                setDispAlertMessage(false);
            }, 5000);
        } else if (response.status !== 200) {
            setDispAlertMessage("Something went wrong");
            setInterval(() => {
                setDispAlertMessage(false);
            }, 5000);
        }

        for (let index in usersList) {
            if (usersList[index] === user_to_be_deleted[0]){
                usersList.splice(Number(index),1);
                setUsersList(usersList);
                setSearch("");
                break;
            }
        }
    }

    // trigger function call to tell the api to delete this user and then refetch user list from the api
    setDelModal(false);
};

// FUNCTION TO HANDLE BULK EDIT OPTION
export const bulkOptionsHandler = (option: any, setDelBulkModal: any) => {
  option?.value === "delete" && setDelBulkModal(true);
};

export const bulkDeleteHandler = async (
    checkedUsers: users[],
    setDispAlertMessage: any,
    usersList: any,
    setUsersList: any,
    setCheckedUsers: any,
    setBulkOptionsDisabled: any,
    setDelBulkModal: any
) => {
    let delUserIDs: string[] = [];
    checkedUsers.map((user: { user_login_id: string }) =>
        delUserIDs.push(user.user_login_id)
    );
    const response: any = await bulkDeleteUserLoginDetail(delUserIDs);
    if (response.status === 200) {
        setDispAlertMessage("Users deleted successfully.");
        setInterval(() => {
            setDispAlertMessage(false);
        }, 5000);
    } else if (response.status !== 200) {
        setDispAlertMessage("Something went wrong");
        setInterval(() => {
            setDispAlertMessage(false);
        }, 5000);
    }
    if (delUserIDs) {
        for (const index in usersList)
            delUserIDs.includes(usersList[index].user_login_id) &&
            delete usersList[index];
        setUsersList(usersList);
        setCheckedUsers([]);
        setBulkOptionsDisabled(true);
    }
    setDelBulkModal(false);
};

  // FUNCTIONS FOR HANDLING DELETE USER LOGIC
export const deleteUserModal = (
    id: string,
    fname: string,
    setdelUserID: any,
    setdelName: any,
    setDelModal: any
) => {
    setdelUserID(id)
    setdelName(fname)
    setDelModal(true)
}

export const selectAllHandler = (
    selectAllCheck: boolean,
    setSelectAll: any,
    setSelectAllCheck: any,
    usersList: users[],
    setUsersList: any,
    setCheckedUsers: any
) => {
    if(selectAllCheck === false){
        setSelectAll(true);
        setSelectAllCheck(true);
        checkAllUsers(usersList, setUsersList, setCheckedUsers);  // sometimes selectall is already true so the useEffect doesnt get triggered so we force the select all logic.
    } else {
        setSelectAll(false)
        setSelectAllCheck(false);
    }
}

export const checkAllUsers = (usersList: users[], setUsersList: any, setCheckedUsers: any) => {
    let updatedUsers = usersList.map((user: users) => {
        user.checked = true
        return user
    })
    setUsersList(updatedUsers)
    setCheckedUsers(updatedUsers)
}

export const updateCheckedStatus = (id: string, usersList: users[], setBulkOptionsDisabled: any, setUsersList: any) => {
    let updatedUsers: users[] = usersList.map((user: users) => {
        if(user["user_login_id"] === id){
            user.checked = !user.checked;
            user.checked === true && setBulkOptionsDisabled(false)
        }
        return user
    });
    setUsersList(updatedUsers)
}


/* ADD & EDIT USER COMPONENT HOOKS */

export const menuAccessOptions = (configs:any) => {
    let label;
    return configs !== undefined && configs.map((config: string) => {
        // capitalize the menu access option
        config = config.split('_').map((e: string) => e.charAt(0).toUpperCase() + e.substring(1)).join('_');
        label = config.replaceAll('_',' ');
        return {value:config.toLowerCase(), label:label}
    })
}
export const siteAccessOptions = (sites:any, userSiteAccess?:Array<string>) => {
    if ( userSiteAccess !== undefined && sites !== undefined) {
        let filteredUserSiteAccess: any[] = [];
        userSiteAccess && userSiteAccess.map((s: any) =>
            sites.map((site: { site_id: string; site_name: string }) => {
                site.site_id === s &&
                filteredUserSiteAccess.push({ value: site.site_id, label: site.site_name });
            })
        );
        return filteredUserSiteAccess;
    }

    return sites !== undefined && sites.map((site: {site_id: string, site_name: string}) => (
        {value:site.site_id, label:site.site_name.replaceAll('_',' ')}
    ));
}

export const cognito_verify_reset_resend = async (type: string, formik: any, setLoginDetailsSent: any) => {
    var user_name = formik.values.username;
    try {
        await manageCognitoUser({
            type: type,
            user_name,
        }).then(response => {
            return response;
        }).then(response => response.json())
        .catch(error => {
            return { returnData: [] };
        });
        let message;
        switch (type) {
            case "verify_email":
                message = "Email verified"
                break;
            case "resend_welcome_email":
                message = "Login details resent"
                setLoginDetailsSent(message);
                break;
            default:
                message = "Success"
        }
        return message;
    } catch (err) {
        // console.log(JSON.stringify(err));
        return false;
    }
}

export const getUser = async (userID: string, setFirstName: (x: string) => void, setLastName: (x: string) => void, setEmail: (x: string) => void, setUsername: (x: string) => void, setUserSiteAccess: (x: []) => void, setUserMenuAccess: (x: []) => void, setSiteAdmin: (x: boolean) => void, setCognitoIdentity: (x: string) => void, setPageLoad: (x: boolean) => void) => {
    setPageLoad(false)
    await getUserById(userID)
    .then(res => {
        res.returnData.first_name && setFirstName(res.returnData.first_name);
        res.returnData.last_name && setLastName(res.returnData.last_name);
        res.returnData.email && setEmail(res.returnData.email);
        res.returnData.username && setUsername(res.returnData.username);
        // res.returnData.mobile && setMobile(res.returnData.mobile)
        // sites returned from API that user has access to
        res.returnData.site_access && setUserSiteAccess(res.returnData.site_access);
        res.returnData.menu_config && setUserMenuAccess(res.returnData.menu_config);
        (res.returnData.site_admin == null) && (res.returnData.site_admin = false);
        setSiteAdmin(res.returnData.site_admin);
        res.returnData.cognito_identity && setCognitoIdentity(res.returnData.cognito_identity);
        setPageLoad(true)
    });
}
export const checkUserStatus = async (username: string, setResendOption: (x: boolean) => void, setVerifyEmail: (x: boolean) => void, formik: any ) => {
    const is_cognito_user: any = await manageCognitoUser({
        'type': 'get_cognito_user_data',
        user_name: username
    }).then(response => {
        return response;
    }).then(response => response.json())
    .catch(error => {
        return { returnData: [] };
    });
    if (is_cognito_user.returnData.hasOwnProperty("UserStatus")) {
        (is_cognito_user.returnData.UserStatus !== 'FORCE_CHANGE_PASSWORD') ? setResendOption(false) : setResendOption(true)
        is_cognito_user.returnData.UserAttributes.map((att: {Name: string, Value: string}) => {
            if(att.Name === "email_verified") (att.Value === "true") ? setVerifyEmail(false) : setVerifyEmail(true);
        })
    }
    // Set Disable Access Toggle
    if (is_cognito_user.returnData.hasOwnProperty('Enabled')) {
        formik.setFieldValue('disableAccess',!is_cognito_user.returnData.Enabled);
    }
    // Set MFA Toggle
    if (is_cognito_user.returnData.hasOwnProperty('UserMFASettingList') && is_cognito_user.returnData.UserMFASettingList.length > 0) {
        formik.setFieldValue('twoStepVerify',true);
    }
}

export const addUserHandler = async (values: any, setLoading: any, menuAccess: any, siteAccess: any, setSuccess: any, setError: any, setErrorMsg: any, history: any) => {
    setLoading(true)
    setError(false);
    setSuccess(false);

    const menu_config: string[] = menuAccess.map((a:{value: string}) => a.value)
    const site_access: string[] = siteAccess.map((a:{value: string}) => a.value)
    const cognito_identity = uuid()
    const cognitoResponse = await manageCognitoUser({
        type: 'create_user',
        user_name: values.username,
        first_name: values.fName,
        last_name: values.lName,
        email:values.email,
        uuid: cognito_identity,
        picture: 'true',
        user_details: ''
    });

    if (cognitoResponse.status !== 200) {
        setLoading(false);
        setError(true);
        cognitoResponse.json().then(function(result) {
            setErrorMsg(result.userMessage);
        });
        return;
    }
    let user_login_id = uuid();
    const userData = {
        user_login_id,
        first_name: values.fName,
        last_name: values.lName,
        username: values.username,
        email: values.email,
        site_admin: values.site_admin,
        menu_config,
        site_access,
        user_type: "user",
        cognito_identity: cognito_identity,
        dashboard_config:["staff_on_campus","visitors_on_campus", "upcoming_scheduled_visits", "overdue_visitors_on_site"]
    }
    const response = await saveUserLoginDetail(userData);
    setLoading(false);
    if (response.status === 200) {
        setSuccess(true);
        history.push(`/users/${user_login_id}`);
    }
    else {
        setError(true)
        setErrorMsg("Could not create user");
    }
}

export const editUserHandler = async (
    setLoading: any,
    menuAccess: any,
    siteAccess: any,
    userID: string,
    values: any,
    cognitoIdentity: any,
    setSuccess: any,
    setError: any
) =>{
    setLoading(true)          
    const menu_config: string[] = menuAccess.map((a:{value: string}) => a.value)
    const site_access: string[] = siteAccess.map((a:{value: string}) => a.value)
    const userData = {
      first_name: values.firstName,
      last_name: values.lastName,
      username: values.username,
      email: values.email,
      site_admin: values.siteAdmin,
      menu_config,
      site_access,
      cognito_identity: cognitoIdentity
    }          
    const response = await updateUserLoginDetail(userID, userData)
    const cognito_update_user_attributes: any = {}
    cognito_update_user_attributes["given_name"] = values.firstName
    cognito_update_user_attributes["family_name"] = values.lastName
    cognito_update_user_attributes["email"] = values.email          

    const cognitoResponse = await manageCognitoUser({
      type: 'update_user',
      user_name: values['username'],
      attributes: cognito_update_user_attributes,
    });

    setLoading(false)
    if (response.status === 200 && cognitoResponse.status === 200) {
        setSuccess(true)
    }
    else {
        setError(true)
    }   
}

export const changeUserStatus = async (username: string, type: string) => {
    try {
        await manageCognitoUser({
            type: type,
            user_name: username,
        }).then(response => {
            return response;
        }).then(response => response.json())
        .catch(error => {
            return { returnData: [] };
        });
    } catch (err) {
        // console.log(JSON.stringify(err));
        return false;
    }
}

// export const checkPasswordValidity = async (username: string, password: string) => {
//     try {
//         let response = await manageCognitoMFA({
//             'user-name': username,
//             'access-token': Constants.ACCESS_TOKEN,
//             password
//         }, {
//             'type': 'enable_mfa'
//         });
//         return response.json()
//     } catch (err) {
//         // console.log(JSON.stringify(err));
//         return false;
//     }
// }

// export const disableMFA = async (username: string) => {
//     try {
//         let response = await manageCognitoMFA({
//             'Authorization': Constants.AUTH_TOKEN,
//             'respond': 'application/json',
//             'access-token': Constants.ACCESS_TOKEN,
//             'user-name': username
//         }, {
//             'type': 'disable_mfa'
//         });
//         return response.json()
//     } catch (err) {
//         // console.log(JSON.stringify(err));
//         return false;
//     }
// }

// export const checkMFAToken = async (mfaCode: string, accessToken: string) => {
//     try {
//         let response = await manageCognitoMFA({
//             'user-code': mfaCode,
//             'access-token': accessToken
//         }, {
//             'type': 'verify_software_token'
//         });
//         return response
//     } catch (err) {
//         // console.log(JSON.stringify(err));
//         return false;
//     }
// }

export * from "../../../hooks/common";
export * from "../../../hooks/utils";
